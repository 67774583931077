import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { RoutesE } from "../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const EmailNotVerified = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <h2>Email не верифіковано</h2>
      <p>На вашу email адресу було відправлено лист з підтвердженням. Будь ласка, перевірте свою електронну пошту та дотримуйтесь інструкцій для підтвердження email.</p>
      <Button
        onClick={() => navigate(RoutesE.LOGIN)}
        variant="contained"
        sx={{ color: "#fff" }}
      >
        Повернутись на сторінку входу
      </Button>
    </div>
  );
};

export default EmailNotVerified;

import ClientNews from "../components/ClientNews/ClientNews";
import CartPage from "../pages/CartPage/CartPage";
import Clients from "../pages/Clients/Clients";
import DefaultPage from "../pages/DefaultPage/DefaultPage";
import DeleteAccount from "../pages/DeleteAccount/DeleteAccount";
import News from "../pages/News/News";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import Reviews from "../pages/Reviews/Reviews";
import Schedule from "../pages/Schedule/Schedule";
import Subscriptions from "../pages/Subscriptions/Subscriptions";
import Teachers from "../pages/Teachers/Teachers";
import { RoutesE } from "./routes";

const privateRoutes = [
  { path: RoutesE.HOME, element: <Clients /> },
  { path: RoutesE.BASKET, element: <CartPage /> },

  // admin/manager
  { path: RoutesE.CLIENTS, element: <Clients /> },
  { path: RoutesE.SUBSCRIPTIONS, element: <Subscriptions /> },
  { path: RoutesE.SCHEDULE, element: <Schedule /> },
  { path: RoutesE.TEACHERS, element: <Teachers /> },
  { path: RoutesE.REVIEWS, element: <Reviews /> },
  { path: RoutesE.PROFILE, element: <ProfilePage /> },
  { path: RoutesE.DELETE_ACCOUNT, element: <DeleteAccount /> },
  { path: RoutesE.NEWS, element: <News /> },
  { path: RoutesE.CLIENT_NEWS, element: <ClientNews /> },
  { path: "*", element: <DefaultPage /> },

  // client
  { path: "/my-subscriptions", element: null },
];

export default privateRoutes;

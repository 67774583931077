import React, { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import Location from "@mui/icons-material/LocationOn";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
//@ts-ignore
import titkok from "../../../../assets/images/tiktok.png";
import { RoutesE } from "../../../../routes/routes";

//@ts-ignore
import styles from "./styles.module.scss";

const ContactsSection = () => {
  return (
    <div className={styles.wrapper} id="contacts-section">
      <h2>Контакти</h2>
      <ul>
        <li>
          <Phone />
          <a href="tel:+380971837000">097 183 7000</a>
        </li>
        <li>
          <Email />
          <a href="mailto:whynotballet.official@gmail.com">
            whynotballet.official@gmail.com
          </a>
        </li>
        <li>
          <Location />
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Volodymyrska+St,+10,+Kyiv,+02000/@50.4558759,30.5163825,17.75z/data=!4m6!3m5!1s0x40d4ce435f22a395:0x8541a9827df43f08!8m2!3d50.4566864!4d30.5162586!16s%2Fg%2F11crvc6mb4"
          >
            М.Київ, вул.Володимирська 10
          </a>
        </li>
        <li>
          <InstagramIcon />
          <a target="_blank" href="https://instagram.com/whynotballet">
            Instagram
          </a>
        </li>
        <li>
          <img src={titkok} alt="tiktok" className={styles.tiktok} />
          <a
            target="_blank"
            href="https://www.tiktok.com/@whynotballet?_t=8ZxSQRm4nkT&_r=1"
          >
            TikTok
          </a>
        </li>
        <li>
          <TextSnippetIcon />
          <a target="_blank" href={RoutesE.PUBLIC_OFFER}>
            Публічна оферта
          </a>
        </li>
      </ul>
      <div className={styles.author}>
        <span>Автор сайту: </span>
        <a href="https://www.instagram.com/ivanp.tv" target="_blank">
          Іван Пятоволенко
        </a>
      </div>
    </div>
  );
};

export default ContactsSection;

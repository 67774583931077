import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoutesE } from "../../routes/routes";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  atLeastOneSpaceRegExp,
  maskPhoneRegex,
  passwordRegExp,
} from "../../utils/helpers/regexp";
import { AuthContext } from "../../services/Auth";
import InputMask from "react-input-mask";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { isMobileOrTablet } from "../../utils/helpers/mediaQueries";
import ukLocale from "date-fns/locale/uk";
import GoogleSignInButton from "../GoogleSignInButton/GoogleSIgnInButton";
import FacebookSignInButton from "../FacebookSignInButton/FacebookSignInButton";

//@ts-ignore
import styles from "./styles.module.scss";

interface Props {
  onSubmit: (data: any) => void;
}

const schema = yup.object().shape({
  email: yup.string().email("Невірний формат").required("Email обов'язковий."),
  password: yup
    .string()
    .matches(
      passwordRegExp,
      "Пароль має містити принаймні одну літеру, одну цифру і мати довжину принаймні 8 символів."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Паролі мають співпадати."),
  fullName: yup
    .string()
    .matches(atLeastOneSpaceRegExp, "Прізвище та ім'я обов'язкові.")
    .required("Прізвище та ім'я обов'язкові."),
  birthDate: yup.string().required("Обов'язкове поле."),
  phone: yup
    .string()
    .matches(maskPhoneRegex, "Неправильний номер телефону")
    .required("Телефон обов'язковий"),
});

const RegisterForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "+380",
    },
  });
  const { loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [birthDate, setBirthDate] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [privacyPolicyApproved, setProvacyPoliceApproved] = useState(false);

  const onLoginClick = () => {
    navigate(RoutesE.LOGIN);
  };

  const onResetPasswordClick = () => {
    navigate(RoutesE.FORGOT_PASSWORD);
  };

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit({ ...data, birthDate }))}
      className={styles.wrapper}
      id="sign-up-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Прізвище та ім'я"
            error={!!errors.fullName}
            helperText={errors?.fullName?.message as string}
            {...register("fullName")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            error={!!errors.email}
            helperText={errors?.email?.message as string}
            {...register("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"phone"}
            render={({ field }) => (
              <InputMask
                mask="+38 (099) 999 99 99"
                value={field.value}
                onChange={field.onChange}
              >
                {/*@ts-ignore*/}
                {() => (
                  <TextField
                    label="Телефон"
                    sx={{ width: "100%" }}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message as string}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Пароль"
            error={!!errors.password}
            helperText={errors?.password?.message as string}
            {...register("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type={showPasswordConfirm ? "text" : "password"}
            label="Повторіть пароль"
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword?.message as string}
            {...register("confirmPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswordConfirm((prev) => !prev)}
                  >
                    {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={ukLocale}>
            {isMobileOrTablet() ? (
              <MobileDatePicker
                label="Дата народження"
                inputFormat="DD.MM.YYYY"
                value={birthDate}
                onChange={setBirthDate as any}
                minDate={dayjs().subtract(80, "years").format("YYYY-DD-MM")}
                maxDate={dayjs().subtract(18, "years").format("YYYY-DD-MM")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("birthDate")}
                    error={!!errors.birthDate}
                    helperText={errors?.birthDate?.message as string}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            ) : (
              <DesktopDatePicker
                label="Дата народження"
                inputFormat="DD.MM.YYYY"
                value={birthDate}
                onChange={setBirthDate as any}
                minDate={dayjs().subtract(80, "years").format("YYYY-DD-MM")}
                maxDate={dayjs().subtract(18, "years").format("YYYY-DD-MM")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("birthDate")}
                    error={!!errors.birthDate}
                    helperText={errors?.birthDate?.message as string}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            )}
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyPolicyApproved}
                  onChange={(e) => setProvacyPoliceApproved(e.target.checked)}
                />
              }
              label={
                <span className={styles.privacyPolicyLabel}>
                  Я прочитав та приймаю умови{" "}
                  <a href={RoutesE.PRIVACY_POLICY} target="_blank">
                    політики конфіденційності
                  </a>
                </span>
              }
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          display="grid"
          gridTemplateColumns="1fr 1fr"
          justifyContent="space-between"
        >
          <Button onClick={onLoginClick}>Увійти</Button>
          <Button
            type="submit"
            form="sign-up-form"
            variant="contained"
            sx={{ color: "#fff" }}
            disabled={loading || !privacyPolicyApproved}
            startIcon={
              loading && <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
          >
            Реєстрація
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ccc",
        }}
      >
        <Typography fontSize={14}>Або увійдіть за допомогою</Typography>
      </Grid>
      <Grid sx={{ display: "flex", justifyContent: "center", paddingTop: 1 }}>
        <GoogleSignInButton />
        <FacebookSignInButton />
      </Grid>
    </form>
  );
};

export default RegisterForm;

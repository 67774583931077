import React, { useCallback, useEffect, useState } from "react";
import { AuthProvider } from "./services/Auth";
import FirebaseProvider from "./services/Firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f9bacf",
      light: "#fbcddc",
      dark: "#ffa1c0",
    },
  },
});

const App = () => {
  const handleResize = useCallback(() => {
    let vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    let vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <FirebaseProvider>
        <AuthProvider />
      </FirebaseProvider>
    </ThemeProvider>
  );
};

export default App;

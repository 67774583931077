import React, { useState } from "react";
import {
  IconButton,
  CircularProgress,
  Button,
  Badge,
  Tooltip,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import useFirestore from "../../utils/hooks/useFirestoreCollection";
import { UserT } from "../../models/models";
import { UserRoleE } from "../../models/enums";
import usePopup from "../../utils/hooks/usePopup";
import CreateEditUser from "./components/CreateEditUser/CreateEditUser";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ClientSubscriptions from "../../pages/Clients/components/ClientSubscriptions/ClientSubscriptions";
import isSubscriptionActive from "../../pages/Clients/helpers/isSubscriptionActive";
import { toast } from "react-toastify";
import AdminTable from "../AdminTable/AdminTable";

//@ts-ignore
import styles from "./styles.module.scss";

const tableData = [
  { value: "fullName", label: "Прізвище та ім'я" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Телефон" },
  { value: "role", label: "Тип", list: UserRoleE },
  { value: "birthDate", label: "Дата народження", type: "date" },
  { value: "registrationDate", label: "Дата реєстрації", type: "date" },
  { value: "comment", label: "Коментар", },
];

const UserList: React.FC = () => {
  const [searchField, setSearchField] = useState("fullName");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const {
    items: users,
    isLoading,
    updateItem,
    loadMore,
  } = useFirestore<UserT>("users", "registrationDate");
  const { createPopup, removePopup } = usePopup();

  const handleDeleteUser = async (user: UserT) => {
    toast.error("Функціонал не імплементовано");
  };

  const handleEdit = (item: UserT) => {
    createPopup({
      header: <>{item ? "Редагувати" : "Створити"} дані користувача</>,
      body: <CreateEditUser item={item} updateItem={updateItem} />,
      footer: (
        <>
          <Button onClick={removePopup}>Відміна</Button>
          <Button form="update-user" type="submit">
            Зберегти
          </Button>
        </>
      ),
    });
  };

  const handleEditSubscriptions = (user: UserT) => {
    createPopup({
      header: <>Управління абонементами користувача "{user.fullName}"</>,
      body: <ClientSubscriptions userId={user.id} />,
    });
  };

  // if (isLoading) {
  //   return (
  //     <div className={styles.loading}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  const actions = [
    {
      icon: (item?: any) => (
        <Tooltip title="Редагувати користувача">
          <IconButton onClick={() => handleEdit(item)}>
            <Edit />
          </IconButton>
        </Tooltip>
      ),
      label: "Редагувати користувача",
      action: handleEdit,
    },
    {
      icon: (item?: any) => (
        <Tooltip title="Видалити користувача">
          <IconButton onClick={() => handleDeleteUser(item)}>
            <Delete />
          </IconButton>
        </Tooltip>
      ),
      label: "Видалити користувача",
      action: handleDeleteUser,
    },
    {
      icon: (item?: any) => (
        <Tooltip
          title={`Абонементи. Активних: ${
            item?.subscriptions?.filter((sub: any) =>
              isSubscriptionActive(
                sub.startDate,
                sub.expireDate,
                sub.visits === Object.keys(sub.visitsPassed)?.length
              )
            )?.length
          }`}
        >
          <IconButton onClick={() => handleEditSubscriptions(item)}>
            <Badge
              badgeContent={
                item?.subscriptions?.filter((sub: any) =>
                  isSubscriptionActive(
                    sub.startDate,
                    sub.expireDate,
                    sub.visits === Object.keys(sub.visitsPassed)?.length
                  )
                )?.length
              }
              color="primary"
            >
              <CreditCardIcon color="info" />
            </Badge>
          </IconButton>
        </Tooltip>
      ),
      label: "Абонементи",
      action: handleEditSubscriptions,
    },
  ];

  return (
    <AdminTable
      items={users}
      tableData={tableData as any}
      actions={actions}
      searchFieldName={searchField}
      setSearchField={setSearchField}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setPageSize={setPageSize}
      pageSize={pageSize}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      loadMore={loadMore}
    />
  );
};

export default UserList;

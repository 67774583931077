import React from "react";

//@ts-ignore
import styles from "./styles.module.scss";

const PublicOffer = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <p className="c14">
          <span className="c11 c15">
            &#1055;&#1059;&#1041;&#1051;&#1030;&#1063;&#1053;&#1040;
            &#1054;&#1060;&#1045;&#1056;&#1058;&#1040;
          </span>
        </p>
        <p className={styles.header}>
          <span className="c11 c15">
            &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056;&#1059;
            &#1055;&#1056;&#1054;{" "}
          </span>
          <span className="c8">
            &#1053;&#1040;&#1044;&#1040;&#1053;&#1053;&#1071;
            &#1055;&#1054;&#1057;&#1051;&#1059;&#1043; &#1047;
            &#1054;&#1057;&#1042;&#1030;&#1058;&#1048; &#1059;
            &#1057;&#1060;&#1045;&#1056;&#1030;
            &#1050;&#1059;&#1051;&#1068;&#1058;&#1059;&#1056;&#1048;
          </span>
        </p>
        <p className="c2 c6">
          <span className={styles.subheader}></span>
        </p>
        <p className="c6 c2">
          <span className="c0"></span>
        </p>
        <p className="c1">
          <span className="c9">
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1103;
            &#1073;&#1072;&#1083;&#1077;&#1090;&#1091;
            &laquo;WhyNotBallet&raquo;, &#1074;
            &#1086;&#1089;&#1086;&#1073;&#1110;
            &#1060;&#1110;&#1079;&#1080;&#1095;&#1085;&#1086;&#1111;
            &#1086;&#1089;&#1086;&#1073;&#1080;
            &#1087;&#1110;&#1076;&#1087;&#1088;&#1080;&#1108;&#1084;&#1094;&#1103;
            &#1050;&#1086;&#1074;&#1072;&#1083;&#1100;&#1095;&#1091;&#1082;
            &#1054;&#1083;&#1077;&#1082;&#1089;&#1072;&#1085;&#1076;&#1088;&#1080;
            &#1052;&#1080;&#1082;&#1086;&#1083;&#1072;&#1111;&#1074;&#1085;&#1080;
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110; -
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;),
            &#1097;&#1086; &#1076;&#1110;&#1108; &#1085;&#1072;
            &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1110;
            &#1042;&#1080;&#1090;&#1103;&#1075;&#1091; &#1079;
            &#1028;&#1076;&#1080;&#1085;&#1086;&#1075;&#1086;
            &#1076;&#1077;&#1088;&#1078;&#1072;&#1074;&#1085;&#1086;&#1075;&#1086;
            &#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1091;
            &#1102;&#1088;&#1080;&#1076;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1086;&#1089;&#1110;&#1073;,
            &#1092;&#1110;&#1079;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1086;&#1089;&#1110;&#1073;-&#1087;&#1110;&#1076;&#1087;&#1088;&#1080;&#1108;&#1084;&#1094;&#1110;&#1074;
            &#1090;&#1072;
            &#1075;&#1088;&#1086;&#1084;&#1072;&#1076;&#1089;&#1100;&#1082;&#1080;&#1093;
            &#1092;&#1086;&#1088;&#1084;&#1091;&#1074;&#1072;&#1085;&#1100;,
            &#1087;&#1088;&#1086;&#1087;&#1086;&#1085;&#1091;&#1108;
            &#1085;&#1077;&#1086;&#1073;&#1084;&#1077;&#1078;&#1077;&#1085;&#1086;&#1084;&#1091;
            &#1082;&#1086;&#1083;&#1091;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1110;&#1074;
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110;&ndash;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1080;)
            &#1091;&#1082;&#1083;&#1072;&#1089;&#1090;&#1080;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;{" "}
          </span>
          <span className="c5">
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;
            &#1086;&#1089;&#1074;&#1110;&#1090;&#1080; &#1091;
            &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1082;&#1091;&#1083;&#1100;&#1090;&#1091;&#1088;&#1080;{" "}
          </span>
          <span className="c0">
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110; -
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;).
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            &#1044;&#1072;&#1085;&#1080;&#1081;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088; &#1108;
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1080;&#1084;,
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1089;&#1090;. &#1089;&#1090;. 633, 634, 641
            &#1062;&#1080;&#1074;&#1110;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
            &#1082;&#1086;&#1076;&#1077;&#1082;&#1089;&#1091;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;
            (&#1076;&#1072;&#1083;&#1110; &mdash; &laquo;&#1062;&#1050;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;&raquo;),
            &#1090;&#1072; &#1081;&#1086;&#1075;&#1086;
            &#1091;&#1084;&#1086;&#1074;&#1080; &#1108;
            &#1086;&#1076;&#1085;&#1072;&#1082;&#1086;&#1074;&#1110;
            &#1076;&#1083;&#1103; &#1074;&#1089;&#1110;&#1093;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;,
            &#1073;&#1077;&#1079;&#1079;&#1072;&#1089;&#1090;&#1077;&#1088;&#1077;&#1078;&#1085;&#1077;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1090;&#1103;
            &#1091;&#1084;&#1086;&#1074; &#1103;&#1082;&#1086;&#1075;&#1086;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1094;&#1110;&#1108;&#1111;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;,
            &#1076;&#1083;&#1103; &#1095;&#1086;&#1075;&#1086;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1108;
            &#1094;&#1077;&#1081;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;.
          </span>
        </p>
        <p className="c1">
          <span className="c9">
            &#1054;&#1089;&#1086;&#1073;&#1072;
            (&#1050;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;),
            &#1103;&#1082;&#1072;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1080;&#1083;&#1072;
            &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1080;,
            &#1085;&#1072;&#1073;&#1091;&#1074;&#1072;&#1108;
            &#1074;&#1089;&#1110;&#1093; &#1087;&#1088;&#1072;&#1074; &#1110;
            &#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1110;&#1074;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072; &#1074;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091; &#1089;&#1090;.
            638 &#1062;&#1050;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
            &#1044;&#1083;&#1103; &#1090;&#1086;&#1075;&#1086;,
            &#1097;&#1086;&#1073;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1080;
            &#1094;&#1102; &#1086;&#1092;&#1077;&#1088;&#1090;&#1091; &#1110;
            &#1091;&#1082;&#1083;&#1072;&#1089;&#1090;&#1080;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;,
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1087;&#1086;&#1074;&#1080;&#1085;&#1077;&#1085;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1080;&#1090;&#1080;
            &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;,
            &#1090;&#1086;&#1073;&#1090;&#1086;
            &#1074;&#1095;&#1080;&#1085;&#1080;&#1090;&#1080;
            &#1076;&#1110;&#1111; &#1097;&#1086;&#1076;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1075;&#1086;&#1076;&#1080; &#1085;&#1072;
            &#1091;&#1082;&#1083;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1085;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;,
            &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1080;&#1093;
            &#1074; &#1086;&#1092;&#1077;&#1088;&#1090;&#1110;.
          </span>
          <span>&nbsp;</span>
          <span className="c9">
            &#1044;&#1110;&#1103;&#1084;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;, &#1097;&#1086;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1086;&#1084;
            &#1094;&#1110;&#1108;&#1111;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1080;{" "}
          </span>
          <span className="c0">
            &#1108; &#1087;&#1086;&#1074;&#1085;&#1072; &#1072;&#1073;&#1086;
            &#1095;&#1072;&#1089;&#1090;&#1082;&#1086;&#1074;&#1072;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1072;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1087;&#1086;&#1095;&#1072;&#1090;&#1086;&#1082;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            &#1059;&#1093;&#1074;&#1072;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1094;&#1110;&#1108;&#1111;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;
            &#1086;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;, &#1097;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1087;&#1086;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;
            &#1086;&#1079;&#1085;&#1072;&#1081;&#1086;&#1084;&#1083;&#1077;&#1085;&#1080;&#1081;
            &#1079; &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;,
            &#1089;&#1087;&#1077;&#1094;&#1080;&#1092;&#1110;&#1082;&#1086;&#1102;
            &#1092;&#1091;&#1085;&#1082;&#1094;&#1110;&#1086;&#1085;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091;. &#1030;
            &#1087;&#1088;&#1080;&#1088;&#1110;&#1074;&#1085;&#1102;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1095;. 2 &#1089;&#1090;. 642 &#1062;&#1050;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080; &#1076;&#1086;
            &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1085;&#1103;
            &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;
            &#1076;&#1074;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1075;&#1086;
            &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1085;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;,
            &#1103;&#1082;&#1110;
            &#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1110;
            &#1085;&#1080;&#1078;&#1095;&#1077; &#1074; &#1094;&#1110;&#1081;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1110;. &#1059;
            &#1088;&#1072;&#1079;&#1110;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1079;&#1072;
            &#1073;&#1072;&#1078;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;,
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1084;&#1086;&#1078;&#1077; &#1073;&#1091;&#1090;&#1080;
            &#1086;&#1092;&#1086;&#1088;&#1084;&#1083;&#1077;&#1085;&#1080;&#1081;
            &#1074; &#1087;&#1088;&#1086;&#1089;&#1090;&#1110;&#1081;
            &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1110;&#1081;
            &#1092;&#1086;&#1088;&#1084;&#1110;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c1">
          <span className="c0">
            &#1058;&#1077;&#1088;&#1084;&#1110;&#1085;&#1080;, &#1097;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1091; &#1094;&#1100;&#1086;&#1084;&#1091;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1110;
            &#1084;&#1072;&#1102;&#1090;&#1100;
            &#1085;&#1072;&#1089;&#1090;&#1091;&#1087;&#1085;&#1077;
            &#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1085;&#1103;:
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1055;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1072;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1072;
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110; &ndash;
            &laquo;&#1054;&#1092;&#1077;&#1088;&#1090;&#1072;&raquo;)
          </span>
          <span className="c9">
            &nbsp;&ndash;
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1095;&#1085;&#1072;
            &#1087;&#1088;&#1086;&#1087;&#1086;&#1079;&#1080;&#1094;&#1110;&#1103;
            &#1060;&#1110;&#1079;&#1080;&#1095;&#1085;&#1086;&#1111;
            &#1086;&#1089;&#1086;&#1073;&#1080;
            &#1087;&#1110;&#1076;&#1087;&#1088;&#1080;&#1108;&#1084;&#1094;&#1103;
            &#1050;&#1086;&#1074;&#1072;&#1083;&#1100;&#1095;&#1091;&#1082;
            &#1054;&#1083;&#1077;&#1082;&#1089;&#1072;&#1085;&#1076;&#1088;&#1080;
            &#1052;&#1080;&#1082;&#1086;&#1083;&#1072;&#1111;&#1074;&#1085;&#1080;
            &#1074;
            &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1072;&#1093;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            (&#1074;&#1080;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1072;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;
            https://www.whynotballet.com),
            &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1074;&#1072;&#1085;&#1072;
            &#1085;&#1077;&#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1086;&#1084;&#1091;
            &#1082;&#1086;&#1083;&#1091;
            &#1092;&#1110;&#1079;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1086;&#1089;&#1110;&#1073;
            (&#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1110;&#1074;),
            &#1091;&#1082;&#1083;&#1072;&#1089;&#1090;&#1080;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;{" "}
          </span>
          <span className="c5">
            &#1079; &#1086;&#1089;&#1074;&#1110;&#1090;&#1080; &#1091;
            &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1082;&#1091;&#1083;&#1100;&#1090;&#1091;&#1088;&#1080;{" "}
          </span>
          <span className="c0">
            &#1085;&#1072;
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1080;&#1093;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;.
            &#1055;&#1077;&#1088;&#1110;&#1086;&#1076;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1090;&#1103;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1080; &#1085;&#1077;
            &#1086;&#1073;&#1084;&#1077;&#1078;&#1077;&#1085;&#1080;&#1081;.
          </span>
        </p>
        <p className="c1">
          <span className="c8">
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;
          </span>
          <span className="c5">&nbsp;&ndash;</span>
          <span className="c8">&nbsp;</span>
          <span className="c0">
            &#1087;&#1088;&#1072;&#1074;&#1086; &#1085;&#1072;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1077;&#1074;&#1085;&#1080;&#1093;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1086;&#1073;&#1091;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1093;
            &#1072;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1086;&#1084;,
            &#1074; &#1087;&#1088;&#1086;&#1076;&#1086;&#1074;&#1078;
            &#1089;&#1090;&#1088;&#1086;&#1082;&#1091;
            &#1081;&#1086;&#1075;&#1086; &#1076;&#1110;&#1111;.
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
          </span>
          <span className="c9">
            &nbsp;&ndash; &#1076;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;{" "}
          </span>
          <span className="c5">
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;
            &#1086;&#1089;&#1074;&#1110;&#1090;&#1080; &#1091;
            &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1082;&#1091;&#1083;&#1100;&#1090;&#1091;&#1088;&#1080;
          </span>
          <span className="c0">
            , &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1080;&#1081;
            &#1079; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1085;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080; &#1079;
            &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1111;&#1111; &#1091;&#1084;&#1086;&#1074;.
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;
          </span>
          <span className="c0">
            &nbsp;&ndash; &#1087;&#1086;&#1074;&#1085;&#1077; &#1090;&#1072;
            &#1073;&#1077;&#1079;&#1091;&#1084;&#1086;&#1074;&#1085;&#1077;,
            &#1073;&#1077;&#1079;&#1079;&#1072;&#1089;&#1090;&#1077;&#1088;&#1077;&#1078;&#1085;&#1077;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1090;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1091;&#1084;&#1086;&#1074;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072; &#1081;&#1086;&#1075;&#1086;
            &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1110;&#1074;.
            &#1052;&#1086;&#1084;&#1077;&#1085;&#1090;&#1086;&#1084;
            &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091; &#1108;
            &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1111; &#1072;&#1073;&#1086;
            &#1095;&#1072;&#1089;&#1090;&#1082;&#1086;&#1074;&#1086;&#1111;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1087;&#1086;&#1095;&#1072;&#1090;&#1086;&#1082;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;.{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
          </span>
          <span className="c0">
            &nbsp;&ndash; &#1060;&#1110;&#1079;&#1080;&#1095;&#1085;&#1072;
            &#1086;&#1089;&#1086;&#1073;&#1072;
            &#1087;&#1110;&#1076;&#1087;&#1088;&#1080;&#1108;&#1084;&#1077;&#1094;&#1100;
            &#1050;&#1086;&#1074;&#1072;&#1083;&#1100;&#1095;&#1091;&#1082;
            &#1054;&#1083;&#1077;&#1082;&#1089;&#1072;&#1085;&#1076;&#1088;&#1072;
            &#1052;&#1080;&#1082;&#1086;&#1083;&#1072;&#1111;&#1074;&#1085;&#1072;,
            &#1103;&#1082;&#1072;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1080;&#1084;
            &#1095;&#1080;&#1085;&#1086;&#1084;
            &#1079;&#1072;&#1088;&#1077;&#1108;&#1089;&#1090;&#1088;&#1086;&#1074;&#1072;&#1085;&#1072;
            &#1090;&#1072;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1102;&#1108;
            &#1089;&#1074;&#1086;&#1102;
            &#1076;&#1110;&#1103;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;, &#1090;&#1072;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091; &#1091;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1076;&#1086; &#1091;&#1084;&#1086;&#1074;
            &#1076;&#1072;&#1085;&#1086;&#1111;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
          </span>
          <span className="c0">
            &nbsp;&ndash;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1085;&#1072;&#1074;&#1095;&#1072;&#1085;&#1085;&#1103;
            &#1073;&#1072;&#1083;&#1077;&#1090;&#1085;&#1086;&#1111;
            &#1084;&#1072;&#1081;&#1089;&#1090;&#1077;&#1088;&#1085;&#1086;&#1089;&#1090;&#1110;,
            &#1103;&#1082;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1085;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1093;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c8">
            &#1053;&#1072;&#1087;&#1088;&#1103;&#1084;&#1086;&#1082;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;{" "}
          </span>
          <span className="c0">
            &ndash; &#1088;&#1110;&#1079;&#1085;&#1086;&#1074;&#1080;&#1076;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1091;
            &#1086;&#1092;&#1092;&#1083;&#1072;&#1081;&#1085;/&#1086;&#1085;&#1083;&#1072;&#1081;&#1085;
            &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;&#1110;
            &#1086;&#1073;&rsquo;&#1108;&#1076;&#1085;&#1072;&#1085;&#1080;&#1093;
            &#1087;&#1077;&#1074;&#1085;&#1086;&#1102;
            &#1090;&#1077;&#1084;&#1072;&#1090;&#1080;&#1082;&#1086;&#1102;
            &#1090;&#1072;
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1086;&#1102;
            &#1085;&#1072;&#1074;&#1095;&#1072;&#1085;&#1085;&#1103;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1090;&#1088;&#1080;&#1074;&#1072;&#1083;&#1110;&#1089;&#1090;&#1102;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1090;&#1103;.
          </span>
        </p>
        <p className="c1">
          <span className="c11">
            &#1057;&#1072;&#1081;&#1090;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110; &ndash;
            &laquo;&#1057;&#1072;&#1081;&#1090;&raquo;)
          </span>
          <span className="c0">
            &nbsp;&ndash;
            &#1086;&#1092;&#1110;&#1094;&#1110;&#1081;&#1085;&#1072;
            &#1074;&#1077;&#1073;-&#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1082;&#1072;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1074;
            &#1051;&#1086;&#1082;&#1072;&#1083;&#1100;&#1085;&#1110;&#1081;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110; &#1090;&#1072; &#1074;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;,
            &#1079;&#1072; &#1072;&#1076;&#1088;&#1077;&#1089;&#1086;&#1102;
            http://www. whynotballet.com, &#1103;&#1082;&#1072; &#1108;
            &#1086;&#1076;&#1085;&#1080;&#1084; &#1079;
            &#1086;&#1089;&#1085;&#1086;&#1074;&#1085;&#1080;&#1093;
            &#1076;&#1078;&#1077;&#1088;&#1077;&#1083;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;.
          </span>
        </p>
        <p className="c1">
          <span className="c8">
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1081;&#1085;&#1110;{" "}
          </span>
          <span className="c11">
            &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;
            (&#1085;&#1072;&#1076;&#1072;&#1083;&#1110; &ndash;
            &laquo;&#1055;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072;&raquo;)
          </span>
          <span className="c0">
            &nbsp;&ndash;
            &#1079;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1081;
            &#1087;&#1077;&#1088;&#1077;&#1083;&#1110;&#1082;
            &#1091;&#1084;&#1086;&#1074;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;
            &#1087;&#1086;&#1074;&#1077;&#1076;&#1110;&#1085;&#1082;&#1080; ,
            &#1074;&#1080;&#1084;&#1086;&#1075; &#1076;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;,
            &#1074;&#1080;&#1076;&#1110;&#1074;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;, &#1111;&#1093;
            &#1086;&#1073;&rsquo;&#1108;&#1084;, &#1090;&#1072;
            &#1110;&#1085;&#1096;&#1086;&#1111;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;&#1111;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;,
            &#1103;&#1082;&#1072;
            &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            (&#1044;&#1086;&#1076;&#1072;&#1090;&#1086;&#1082; &#8470; 1
            &#1076;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;).
          </span>
        </p>
        <p className="c1">
          <span className="c8">
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1103;{" "}
          </span>
          <span className="c0">
            &ndash;
            &#1073;&#1091;&#1076;&#1110;&#1074;&#1083;&#1103;/&#1087;&#1088;&#1080;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;,
            &#1074; &#1103;&#1082;&#1086;&#1084;&#1091;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091; &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1076;&#1086;&#1076;&#1077;&#1088;&#1078;&#1072;&#1085;&#1085;&#1103;
            &#1091;&#1089;&#1110;&#1093; &#1074;&#1080;&#1084;&#1086;&#1075;
            &#1076;&#1072;&#1085;&#1086;&#1111;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;, &#1097;&#1086;
            &#1079;&#1072;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1072;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            https://www. whynotballet.com
          </span>
        </p>
        <p className="c3 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            1.&#1055;&#1056;&#1045;&#1044;&#1052;&#1045;&#1058;
            &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056;&#1059;
          </span>
        </p>
        <p className="c1">
          <span className="c5">
            1.1. &#1055;&#1088;&#1077;&#1076;&#1084;&#1077;&#1090;&#1086;&#1084;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1108;
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;
            &#1086;&#1089;&#1074;&#1110;&#1090;&#1080; &#1091;
            &#1089;&#1092;&#1077;&#1088;&#1110;
            &#1082;&#1091;&#1083;&#1100;&#1090;&#1091;&#1088;&#1080;{" "}
          </span>
          <span className="c0">
            &#1091; &#1086;&#1092;&#1092;&#1083;&#1072;&#1081;&#1085;
            &#1110;/&#1072;&#1073;&#1086;
            &#1086;&#1085;&#1083;&#1072;&#1081;&#1085;
            &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;&#1110; &#1079;&#1072;
            &#1074;&#1080;&#1085;&#1072;&#1075;&#1086;&#1088;&#1086;&#1076;&#1091;,
            &#1097;&#1086;
            &#1074;&#1080;&#1087;&#1083;&#1072;&#1095;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1085;&#1072; &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1100;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;..{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            1.2.
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1085;&#1072; &#1089;&#1074;&#1110;&#1081;
            &#1088;&#1086;&#1079;&#1089;&#1091;&#1076;
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108; &#1110;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1091;&#1108;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110; &#1090;&#1072;
            &#1110;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1072;&#1093;
            &#1088;&#1110;&#1079;&#1085;&#1110;
            &#1074;&#1072;&#1088;&#1110;&#1072;&#1094;&#1110;&#1111;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;, &#1103;&#1082;&#1110;
            &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1074;&#1110;&#1076;&#1088;&#1110;&#1079;&#1085;&#1103;&#1090;&#1080;&#1089;&#1103;
            &#1079;&#1072; &#1086;&#1073;&#1089;&#1103;&#1075;&#1086;&#1084;,
            &#1085;&#1072;&#1087;&#1086;&#1074;&#1085;&#1077;&#1085;&#1085;&#1103;&#1084;,
            &#1089;&#1090;&#1088;&#1086;&#1082;&#1072;&#1084;&#1080; &#1110;
            &#1074;&#1072;&#1088;&#1090;&#1110;&#1089;&#1090;&#1102;.{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            1.3. &#1054;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1102;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1087;&#1088;&#1080;&#1076;&#1073;&#1072;&#1085;&#1085;&#1103;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1085;&#1072; &#1086;&#1073;&#1088;&#1072;&#1085;&#1080;&#1081;
            &#1085;&#1080;&#1084;
            &#1085;&#1072;&#1087;&#1088;&#1103;&#1084;&#1086;&#1082;
            &#1090;&#1072; &#1087;&#1077;&#1074;&#1085;&#1091;
            &#1082;&#1110;&#1083;&#1100;&#1082;&#1110;&#1089;&#1090;&#1100;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1090;&#1072;
            &#1081;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1076;&#1072;&#1083;&#1100;&#1096;&#1077;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1091;&#1084;&#1086;&#1074;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1072;&#1073;&#1086;
            &#1087;&#1088;&#1080;&#1076;&#1073;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1072;&#1074;&#1072;
            &#1088;&#1072;&#1079;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;.
            &#1040;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1110; &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1110;
            &#1076;&#1083;&#1103;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1080;,
            &#1111;&#1093;
            &#1074;&#1072;&#1088;&#1110;&#1072;&#1094;&#1110;&#1111;,
            &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;&#1080;,
            &#1074;&#1072;&#1088;&#1090;&#1110;&#1089;&#1090;&#1100;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1110;
            &#1085;&#1072;
            &#1074;&#1077;&#1073;-&#1089;&#1090;&#1086;&#1088;&#1110;&#1085;&#1094;&#1110;
            &#1057;&#1072;&#1081;&#1090;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            1.4. &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1055;&#1088;&#1072;&#1074;&#1080;&#1083;,
            &#1085;&#1072;&#1074;&#1077;&#1076;&#1077;&#1085;&#1080;&#1093;
            &#1091; &#1044;&#1086;&#1076;&#1072;&#1090;&#1082;&#1091; &#8470; 1
            &#1076;&#1086; &#1076;&#1072;&#1085;&#1086;&#1111;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;,
            &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1072;
            &#1088;&#1077;&#1076;&#1072;&#1082;&#1094;&#1110;&#1103;
            &#1103;&#1082;&#1080;&#1093;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1072;
            &#1079;&#1072;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1085;&#1085;&#1103;&#1084;:
            https://www. whynotballet.com,
            &#1072;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1076;&#1072;&#1085;&#1110;
            &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;&#1072; &#1090;&#1072;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1079;&#1075;&#1086;&#1076;&#1091; &#1085;&#1072;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className="c8">2.</span>
          <span className="c5">&nbsp;</span>
          <span className={styles.subheader}>
            &#1057;&#1058;&#1056;&#1054;&#1050; &#1030;
            &#1055;&#1054;&#1056;&#1071;&#1044;&#1054;&#1050;
            &#1053;&#1040;&#1044;&#1040;&#1053;&#1053;&#1071;
            &#1055;&#1054;&#1057;&#1051;&#1059;&#1043;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            2.1. &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1079;&#1072; &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1087;&#1088;&#1072;&#1094;&#1110;&#1074;&#1085;&#1080;&#1082;&#1072;&#1084;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1086;&#1092;&#1092;&#1083;&#1072;&#1081;&#1085; &#1074;
            &#1079;&#1072;&#1083;&#1110; &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1086;&#1085;&#1083;&#1072;&#1081;&#1085; &#1074;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;
            &#1079;&#1072;
            &#1076;&#1086;&#1087;&#1086;&#1084;&#1086;&#1075;&#1086;&#1102;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1080;
            &#1090;&#1072;
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
            (&#1055;&#1054;) &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1074;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1080;&#1081;
            &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1075;&#1086;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;
            &#1076;&#1086;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1110;&#1074;
            &#1082;&#1091;&#1088;&#1089;&#1091;, &#1072;&#1073;&#1086; &#1074;
            &#1110;&#1085;&#1090;&#1077;&#1088;&#1072;&#1082;&#1090;&#1080;&#1074;&#1085;&#1086;&#1084;&#1091;
            &#1086;&#1095;&#1085;&#1086;&#1084;&#1091;
            &#1092;&#1086;&#1088;&#1084;&#1072;&#1090;&#1110;,
            &#1074;&#1080;&#1093;&#1086;&#1076;&#1103;&#1095;&#1080;
            &#1110;&#1079;
            &#1089;&#1087;&#1077;&#1094;&#1080;&#1092;&#1110;&#1082;&#1080;
            &#1110;
            &#1086;&#1089;&#1086;&#1073;&#1083;&#1080;&#1074;&#1086;&#1089;&#1090;&#1077;&#1081;
            &#1086;&#1073;&#1088;&#1072;&#1085;&#1086;&#1075;&#1086; &#1110;
            &#1086;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1072;&#1073;&#1086; &#1087;&#1088;&#1072;&#1074;&#1072;
            &#1088;&#1072;&#1079;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            2.2. &#1057;&#1090;&#1088;&#1086;&#1082; &#1090;&#1072;
            &#1076;&#1077;&#1090;&#1072;&#1083;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1086;&#1073;&#1091;&#1084;&#1086;&#1074;&#1083;&#1102;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1089;&#1087;&#1077;&#1094;&#1080;&#1092;&#1110;&#1082;&#1086;&#1102;
            &#1110;
            &#1086;&#1089;&#1086;&#1073;&#1083;&#1080;&#1074;&#1110;&#1089;&#1090;&#1102;
            &#1086;&#1073;&#1088;&#1072;&#1085;&#1086;&#1075;&#1086; &#1110;
            &#1086;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1072;&#1073;&#1086; &#1087;&#1088;&#1072;&#1074;&#1072;
            &#1088;&#1072;&#1079;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;.
            &#1058;&#1072;&#1082;&#1110;
            &#1074;&#1110;&#1076;&#1086;&#1084;&#1086;&#1089;&#1090;&#1110;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110; &#1074;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1081;&#1085;&#1086;&#1084;&#1091;
            &#1073;&#1083;&#1086;&#1094;&#1110;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1080;&#1093;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            2.3. &#1053;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1087;&#1086;&#1095;&#1080;&#1085;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1079; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072; &#1074;
            &#1088;&#1072;&#1093;&#1091;&#1085;&#1086;&#1082;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1072;&#1073;&#1086;
            &#1088;&#1072;&#1079;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            2.4. &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1087;&#1077;&#1088;&#1077;&#1076;
            &#1087;&#1088;&#1080;&#1076;&#1073;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;
            &#1072;&#1085;&#1072;&#1083;&#1110;&#1079;&#1091;&#1108;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1074;&#1110;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1084;&#1110;&#1089;&#1094;&#1100; &#1091;
            &#1073;&#1072;&#1078;&#1072;&#1085;&#1110;
            &#1076;&#1072;&#1090;&#1080; &#1090;&#1072; &#1095;&#1072;&#1089;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1090;&#1072;
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;
            &#1087;&#1088;&#1080;&#1081;&#1084;&#1072;&#1108;
            &#1088;&#1110;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1097;&#1086;&#1076;&#1086;
            &#1087;&#1088;&#1080;&#1076;&#1073;&#1072;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c5">
            2.5. &#1042;&#1083;&#1072;&#1089;&#1085;&#1080;&#1081;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;, &#1103;&#1082;&#1110;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1086;&#1084;,
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1102;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;
            &#1085;&#1072; &#1074;&#1083;&#1072;&#1089;&#1085;&#1080;&#1081;
            &#1088;&#1086;&#1079;&#1089;&#1091;&#1076; &#1085;&#1072;
            &#1057;&#1072;&#1081;&#1090;&#1110;{" "}
          </span>
          <span className="c5 c7">
            (&#1072;&#1073;&#1086;
            &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1086;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1089;&#1086;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110; &#1072;&#1073;&#1086;
            &#1084;&#1077;&#1089;&#1077;&#1085;&#1076;&#1078;&#1077;&#1088;&#1080;)
          </span>
          <span className="c5">
            &nbsp;&#1074;&#1080;&#1093;&#1086;&#1076;&#1103;&#1095;&#1080;
            &#1079;: &#1085;&#1072;&#1087;&#1088;&#1103;&#1084;&#1082;&#1091;
            &#1086;&#1073;&#1088;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;,
            &#1088;&#1086;&#1079;&#1082;&#1083;&#1072;&#1076;&#1091;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1093;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1090;&#1072;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1074;&#1110;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1084;&#1110;&#1089;&#1094;&#1100; &#1085;&#1072;
            &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;
            &#1079;&#1072;&#1087;&#1080;&#1089;&#1091; &#1085;&#1072;
            &#1087;&#1077;&#1074;&#1085;&#1091; &#1076;&#1072;&#1090;&#1091;
            &#1090;&#1072; &#1095;&#1072;&#1089;.{" "}
          </span>
          <span className="c5 c7">
            &#1059; &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1086;&#1075;&#1086;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;&#1091;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1089;&#1086;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110; &#1072;&#1073;&#1086;
            &#1084;&#1077;&#1089;&#1077;&#1085;&#1076;&#1078;&#1077;&#1088;&#1080;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1080;&#1081;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;
            &#1088;&#1072;&#1093;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1080;&#1084;
            &#1079; &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1074;&#1110;&#1076;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &nbsp;&#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1086; &#1094;&#1077; &#1079;
            &#1073;&#1086;&#1082;&#1091;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1091;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1100;
            &#1085;&#1072;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            3. &#1055;&#1056;&#1040;&#1042;&#1040; &#1058;&#1040;
            &#1054;&#1041;&#1054;&#1042;&rsquo;&#1071;&#1047;&#1050;&#1048;
            &#1057;&#1058;&#1054;&#1056;&#1030;&#1053;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            3.1
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091;
            &#1086;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1086;&#1089;&#1090;&#1072;&#1085;&#1085;&#1110;&#1084;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072; &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;
            &#1086;&#1073;&#1088;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1079;&#1072; &#1091;&#1084;&#1086;&#1074;&#1080;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084; &#1087;.
            2.4. &#1090;&#1072; 2.5. &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;. &#1059;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091; &#1085;&#1077;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            (&#1072;&#1073;&#1086; &#1085;&#1077;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1075;&#1086;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;)
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;&#1091;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1087;. 2.5. &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;,
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1079;&#1074;&#1110;&#1083;&#1100;&#1085;&#1103;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1110;&#1076;
            &#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &nbsp;&#1087;&#1086;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;
            &#1072;&#1073;&#1086;
            &#1095;&#1072;&#1089;&#1090;&#1082;&#1086;&#1074;&#1086;
            (&#1085;&#1072; &#1095;&#1072;&#1089;&#1090;&#1080;&#1085;&#1091;
            &#1085;&#1077;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;&#1091;);
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074; &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091; &#1110;
            &#1074; &#1088;&#1072;&#1084;&#1082;&#1072;&#1093;
            &#1090;&#1077;&#1088;&#1084;&#1110;&#1085;&#1110;&#1074;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1080;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
            &#1074;&#1110;&#1076;&#1077;&#1086;&#1079;&#1072;&#1087;&#1080;&#1089;&#1110;&#1074;
            &#1090;&#1088;&#1077;&#1085;&#1091;&#1074;&#1072;&#1085;&#1100;
            &#1090;&#1072; &#1110;&#1085;&#1096;&#1086;&#1111;
            &#1089;&#1091;&#1087;&#1091;&#1090;&#1085;&#1100;&#1086;&#1111;
            &#1110;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1086;&#1111;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1074;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1084;&#1091;
            &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;
            &#1089;&#1072;&#1081;&#1090;&#1091; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;,
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1075;&#1086;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1085;&#1072; &#1103;&#1082;&#1086;&#1084;&#1091;
            &#1079;&#1085;&#1072;&#1093;&#1086;&#1076;&#1103;&#1090;&#1100;&#1089;&#1103;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;.
            &#1044;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1075;&#1086;
            &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1091;
            &#1089;&#1072;&#1081;&#1090;&#1091; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1084;&#1091;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1085;&#1072; &#1103;&#1082;&#1086;&#1084;&#1091;
            &#1079;&#1085;&#1072;&#1093;&#1086;&#1076;&#1103;&#1090;&#1100;&#1089;&#1103;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;
            &#1082;&#1091;&#1088;&#1089;&#1091;,
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1080;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1087;&#1110;&#1089;&#1083;&#1103;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1111;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1074;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;,
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1077;&#1085;&#1086;&#1084;&#1091;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1086;&#1095;&#1085;&#1086;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1081;&#1085;&#1086;-&#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1100;&#1090;&#1072;&#1094;&#1110;&#1081;&#1085;&#1110;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
            &#1103;&#1082;&#1110;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1086;&#1073;&#1088;&#1072;&#1085;&#1080;&#1084; &#1110;
            &#1089;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1080;&#1084;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1040;&#1073;&#1086;&#1085;&#1077;&#1084;&#1077;&#1085;&#1090;&#1086;&#1084;
            &#1072;&#1073;&#1086;
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1086;&#1102;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1089;&#1090;&#1088;&#1086;&#1075;&#1086;
            &#1079;&#1073;&#1077;&#1088;&#1110;&#1075;&#1072;&#1090;&#1080;
            &#1074; &#1090;&#1072;&#1108;&#1084;&#1085;&#1080;&#1094;&#1110;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1091;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1091;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1091;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1091;
            &#1074;&#1110;&#1076;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1087;&#1088;&#1080;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1110;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086;
            &#1055;&#1086;&#1083;&#1110;&#1090;&#1080;&#1082;&#1080;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1110; &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;,
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1086;&#1111;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            -
            &#1076;&#1086;&#1090;&#1088;&#1080;&#1084;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1074;&#1080;&#1084;&#1086;&#1075;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;, &#1074; &#1090;.
            &#1095;. &#1097;&#1086;
            &#1089;&#1090;&#1086;&#1089;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1080;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1110; &#1110;
            &#1079;&#1072;&#1093;&#1080;&#1089;&#1090;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072; &#1074;&#1080;&#1084;&#1086;&#1075;&#1091;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080; &#1040;&#1082;&#1090;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1090;&#1072;&#1082;&#1080;&#1081; &#1040;&#1082;&#1090;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1089;&#1082;&#1083;&#1072;&#1076;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1074; 2 (&#1076;&#1074;&#1086;&#1093;)
            &#1072;&#1074;&#1090;&#1077;&#1085;&#1090;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1080;&#1084;&#1110;&#1088;&#1085;&#1080;&#1082;&#1072;&#1093;
            &#1090;&#1072; &#1086;&#1076;&#1080;&#1085; &#1079;
            &#1085;&#1080;&#1093;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091;. &#1047;&#1072;
            &#1074;&#1110;&#1076;&#1089;&#1091;&#1090;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1076;&#1086; 10
            (&#1076;&#1077;&#1089;&#1103;&#1090;&#1086;&#1075;&#1086;)
            &#1095;&#1080;&#1089;&#1083;&#1072;
            &#1084;&#1110;&#1089;&#1103;&#1094;&#1103;,
            &#1085;&#1072;&#1089;&#1090;&#1091;&#1087;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1072; &#1084;&#1110;&#1089;&#1103;&#1094;&#1077;&#1084;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1079;&#1072;&#1091;&#1074;&#1072;&#1078;&#1077;&#1085;&#1100;
            &#1090;&#1072;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1100;
            &#1074;&#1110;&#1076;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;,
            &#1040;&#1082;&#1090;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1080;&#1084;
            &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1080;&#1081;,
            &#1072; &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &ndash;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1084;&#1080; &#1074;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1084;&#1091;
            &#1086;&#1073;&#1089;&#1103;&#1079;&#1110; &#1090;&#1072;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;&#1111;
            &#1103;&#1082;&#1086;&#1089;&#1090;&#1110;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            &#1059; &#1088;&#1072;&#1079;&#1110;, &#1103;&#1082;&#1097;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090; &#1085;&#1077;
            &#1079;&#1074;&#1077;&#1088;&#1085;&#1091;&#1074;&#1089;&#1103;
            &#1076;&#1086;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1079;
            &#1087;&#1088;&#1086;&#1093;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080; &#1040;&#1082;&#1090;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1076;&#1086; 10
            (&#1076;&#1077;&#1089;&#1103;&#1090;&#1086;&#1075;&#1086;)
            &#1095;&#1080;&#1089;&#1083;&#1072;
            &#1084;&#1110;&#1089;&#1103;&#1094;&#1103;,
            &#1085;&#1072;&#1089;&#1090;&#1091;&#1087;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1072; &#1084;&#1110;&#1089;&#1103;&#1094;&#1077;&#1084;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;, &#1079;&#1072;
            &#1074;&#1110;&#1076;&#1089;&#1091;&#1090;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;&#1072;&#1091;&#1074;&#1072;&#1078;&#1077;&#1085;&#1100;
            &#1090;&#1072;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1100;
            &#1074;&#1110;&#1076;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1084;&#1080; &#1074;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1084;&#1091;
            &#1086;&#1073;&#1089;&#1103;&#1079;&#1110; &#1090;&#1072;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;&#1111;
            &#1103;&#1082;&#1086;&#1089;&#1090;&#1110;.{" "}
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c1">
          <span className="c0">
            3.2.
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1084;&#1072;&#1108; &#1087;&#1088;&#1072;&#1074;&#1086;:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;
            &#1085;&#1072;&#1087;&#1086;&#1074;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1057;&#1072;&#1081;&#1090;&#1091;, &#1074;
            &#1090;&#1086;&#1084;&#1091; &#1095;&#1080;&#1089;&#1083;&#1110;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1075;&#1086;
            &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1091;,
            &#1072;&#1073;&#1086; &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1092;&#1086;&#1088;&#1084;&#1091;
            &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1085;&#1103;
            &#1079;&#1074;&#1086;&#1088;&#1086;&#1090;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091; &#1090;&#1072;
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1100;&#1090;&#1072;&#1094;&#1110;&#1081;,
            &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1110;
            &#1076;&#1086;&#1087;&#1086;&#1074;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1088;&#1086;&#1079;&#1082;&#1083;&#1072;&#1076;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1090;&#1072;
            &#1111;&#1093;
            &#1085;&#1072;&#1087;&#1088;&#1103;&#1084;&#1082;&#1080;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074; &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091;
            &#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1089;&#1087;&#1077;&#1094;&#1110;&#1072;&#1083;&#1110;&#1079;&#1086;&#1074;&#1072;&#1085;&#1110;
            &#1088;&#1077;&#1082;&#1086;&#1084;&#1077;&#1085;&#1076;&#1072;&#1094;&#1110;&#1111;
            &#1097;&#1086;&#1076;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1100;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1080;&#1093;
            &#1082;&#1091;&#1088;&#1089;&#1086;&#1084;,
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1086;&#1102;,
            &#1072; &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1097;&#1086;&#1076;&#1086;
            &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1080;&#1093;
            &#1076;&#1110;&#1081;, &#1103;&#1082;&#1110;
            &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1102;&#1090;&#1100;
            &#1079;&#1073;&#1110;&#1083;&#1100;&#1096;&#1080;&#1090;&#1080;
            &#1077;&#1092;&#1077;&#1082;&#1090;&#1080;&#1074;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1077;
            &#1076;&#1086;&#1087;&#1091;&#1089;&#1082;&#1072;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072; &#1076;&#1086;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;,
            &#1103;&#1082;&#1097;&#1086;
            &#1079;&#1086;&#1074;&#1085;&#1110;&#1096;&#1085;&#1110;&#1081;
            &#1074;&#1080;&#1075;&#1083;&#1103;&#1076;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1076;&#1072;&#1108;
            &#1089;&#1087;&#1110;&#1074;&#1088;&#1086;&#1073;&#1110;&#1090;&#1085;&#1080;&#1082;&#1072;&#1084;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1080;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1090;&#1080;, &#1097;&#1086;
            &#1086;&#1089;&#1090;&#1072;&#1085;&#1085;&#1110;&#1081;
            &#1087;&#1077;&#1088;&#1077;&#1073;&#1091;&#1074;&#1072;&#1108;
            &#1091; &#1089;&#1090;&#1072;&#1085;&#1110;
            &#1072;&#1083;&#1082;&#1086;&#1075;&#1086;&#1083;&#1100;&#1085;&#1086;&#1075;&#1086;
            &#1089;&#1087;&rsquo;&#1103;&#1085;&#1110;&#1085;&#1085;&#1103;
            &#1072;&#1073;&#1086; &#1087;&#1110;&#1076;
            &#1074;&#1087;&#1083;&#1080;&#1074;&#1086;&#1084;
            &#1076;&#1110;&#1111;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1093; &#1079;
            &#1085;&#1072;&#1088;&#1082;&#1086;&#1090;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1088;&#1077;&#1095;&#1086;&#1074;&#1080;&#1085;,
            &#1072;&#1073;&#1086; &#1087;&#1110;&#1076;
            &#1076;&#1110;&#1108;&#1102;
            &#1084;&#1077;&#1076;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1077;&#1087;&#1072;&#1088;&#1072;&#1090;&#1110;&#1074;,
            &#1103;&#1082;&#1097;&#1086; &#1094;&#1077;
            &#1074;&#1087;&#1083;&#1080;&#1074;&#1072;&#1108; &#1085;&#1072;
            &#1087;&#1086;&#1074;&#1077;&#1076;&#1110;&#1085;&#1082;&#1091;,
            &#1088;&#1077;&#1072;&#1082;&#1094;&#1110;&#1102; &#1090;&#1072;
            &#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1110;&#1089;&#1090;&#1100;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;, &#1110;
            &#1103;&#1082;&#1097;&#1086;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;&#1080;,
            &#1087;&#1077;&#1088;&#1077;&#1083;&#1110;&#1095;&#1077;&#1085;&#1110;
            &#1074; &#1094;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1091;&#1085;&#1082;&#1090;&#1110;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1108;
            &#1086;&#1095;&#1077;&#1074;&#1080;&#1076;&#1085;&#1080;&#1084;&#1080;
            &#1076;&#1083;&#1103;
            &#1089;&#1087;&#1110;&#1074;&#1088;&#1086;&#1073;&#1110;&#1090;&#1085;&#1080;&#1082;&#1110;&#1074;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
            &#1074;&#1110;&#1076;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1074;&#1110;&#1076;&#1096;&#1082;&#1086;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1085;&#1080;&#1084;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1110;&#1074; &#1090;&#1072;
            &#1085;&#1072;&#1085;&#1077;&#1089;&#1077;&#1085;&#1086;&#1111;
            &#1096;&#1082;&#1086;&#1076;&#1080;
            (&#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1079;&#1073;&#1080;&#1090;&#1082;&#1080; &#1090;&#1072;
            &#1084;&#1086;&#1088;&#1072;&#1083;&#1100;&#1085;&#1072;
            &#1096;&#1082;&#1086;&#1076;&#1072;) &#1085;&#1072;
            &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1100;
            &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1086;&#1111;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111; &#1072;&#1073;&#1086;
            &#1085;&#1072; &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1100;
            &#1082;&#1086;&#1085;&#1082;&#1088;&#1077;&#1090;&#1085;&#1080;&#1093;
            &#1089;&#1087;&#1110;&#1074;&#1088;&#1086;&#1073;&#1110;&#1090;&#1085;&#1080;&#1082;&#1110;&#1074;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111; (&#1074;
            &#1079;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1074;&#1110;&#1076;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;),
            &#1103;&#1082;&#1097;&#1086; &#1085;&#1077;
            &#1076;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1086;,
            &#1097;&#1086; &#1096;&#1082;&#1086;&#1076;&#1072;
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1072; &#1085;&#1077;
            &#1079; &#1074;&#1080;&#1085;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1088;&#1086;&#1079;&#1074;&#1080;&#1074;&#1072;&#1090;&#1080;
            (&#1088;&#1086;&#1079;&#1096;&#1080;&#1088;&#1102;&#1074;&#1072;&#1090;&#1080;)
            &#1082;&#1086;&#1084;&#1087;&#1083;&#1077;&#1082;&#1089;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1073;&#1077;&#1079;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1079; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;
            &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1110;
            &#1076;&#1086;&#1087;&#1086;&#1074;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1091;&#1084;&#1086;&#1074;&#1080;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1073;&#1077;&#1079;
            &#1087;&#1086;&#1087;&#1077;&#1088;&#1077;&#1076;&#1085;&#1100;&#1086;&#1075;&#1086;
            &#1091;&#1079;&#1075;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1079; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1072;&#1073;&#1086;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1086;&#1075;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;&#1076;&#1072;&#1102;&#1095;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1091;&#1108;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1080;&#1084;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1079;&#1072;&#1083;&#1091;&#1095;&#1077;&#1085;&#1080;&#1084;
            &#1082;&#1074;&#1072;&#1083;&#1110;&#1092;&#1110;&#1082;&#1086;&#1074;&#1072;&#1085;&#1080;&#1084;
            &#1090;&#1072;
            &#1087;&#1088;&#1086;&#1092;&#1077;&#1089;&#1110;&#1081;&#1085;&#1080;&#1084;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1086;&#1084;.
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1091;&#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1102;&#1108;,
            &#1097;&#1086; &#1079;&#1084;&#1110;&#1085;&#1072;
            &#1089;&#1082;&#1083;&#1072;&#1076;&#1091;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1091;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111; &#1085;&#1077; &#1108;
            &#1087;&#1110;&#1076;&#1089;&#1090;&#1072;&#1074;&#1086;&#1102;
            &#1076;&#1083;&#1103;
            &#1088;&#1086;&#1079;&#1110;&#1088;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072;
            &#1087;&#1086;&#1074;&#1077;&#1088;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1075;&#1088;&#1086;&#1096;&#1086;&#1074;&#1080;&#1093;
            &#1082;&#1086;&#1096;&#1090;&#1110;&#1074;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;
            &#1088;&#1086;&#1079;&#1110;&#1088;&#1074;&#1072;&#1090;&#1080;
            &#1094;&#1077;&#1081;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088; &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1072;&#1093; &#1110;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1080;&#1093;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            -
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1073;&#1077;&#1079;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1079; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1092;&#1086;&#1090;&#1086;- &#1090;&#1072;
            &#1074;&#1110;&#1076;&#1077;&#1086;&#1079;&#1081;&#1086;&#1084;&#1082;&#1091;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1090;&#1072;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1074;&#1110;&#1076;&#1079;&#1085;&#1103;&#1090;&#1110;
            &#1092;&#1086;&#1090;&#1086;- &#1090;&#1072;
            &#1074;&#1110;&#1076;&#1077;&#1086;&#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;
            &#1085;&#1072; &#1074;&#1083;&#1072;&#1089;&#1085;&#1080;&#1081;
            &#1088;&#1086;&#1079;&#1089;&#1091;&#1076;;{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1079;&#1084;&#1110;&#1085;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1089;&#1087;&#1077;&#1094;&#1110;&#1072;&#1083;&#1110;&#1089;&#1090;&#1110;&#1074;
            &#1103;&#1082;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1090;&#1072;
            &#1075;&#1088;&#1091;&#1087;&#1091; &#1076;&#1086;
            &#1103;&#1082;&#1086;&#1111;
            &#1074;&#1082;&#1083;&#1102;&#1095;&#1077;&#1085;&#1080;&#1081;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1110;&#1085;&#1096;&#1110; &#1087;&#1088;&#1072;&#1074;&#1072;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1090;&#1072; &#1095;&#1080;&#1085;&#1085;&#1080;&#1084;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            3.3. &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            -
            &#1086;&#1087;&#1083;&#1072;&#1095;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1085;&#1072;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1093; &#1090;&#1072; &#1074;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1087;&#1088;&#1072;&#1074;&#1076;&#1080;&#1074;&#1110;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1085;&#1110;
            (&#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1085;&#1077; &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1079;&#1072;
            &#1076;&#1086;&#1089;&#1090;&#1086;&#1074;&#1110;&#1088;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;);
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1087;&#1088;&#1080;&#1081;&#1085;&#1103;&#1090;&#1080; &#1074;
            &#1084;&#1110;&#1088;&#1091;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086;
            &#1087;&#1086;&#1083;&#1086;&#1078;&#1077;&#1085;&#1100;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1086;&#1093;&#1072;&#1081;&#1085;&#1086; &#1090;&#1072;
            &#1076;&#1073;&#1072;&#1081;&#1083;&#1080;&#1074;&#1086;
            &#1089;&#1090;&#1072;&#1074;&#1080;&#1090;&#1080;&#1089;&#1100;
            &#1076;&#1086;
            &#1086;&#1073;&#1083;&#1072;&#1076;&#1085;&#1072;&#1085;&#1085;&#1103;,
            &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1086;&#1075;&#1086;
            &#1110;&#1085;&#1074;&#1077;&#1085;&#1090;&#1072;&#1088;&#1102;
            &#1090;&#1072; &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1084;&#1072;&#1081;&#1085;&#1072;, &#1103;&#1082;&#1077;
            &#1079;&#1085;&#1072;&#1093;&#1086;&#1076;&#1080;&#1090;&#1100;&#1089;&#1103;
            &#1074; &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            -
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;
            &#1089;&#1090;&#1077;&#1078;&#1080;&#1090;&#1080; &#1079;&#1072;
            &#1089;&#1074;&#1086;&#1111;&#1084;&#1080;
            &#1088;&#1077;&#1095;&#1072;&#1084;&#1080; &#1090;&#1072;
            &#1085;&#1077;
            &#1079;&#1072;&#1083;&#1080;&#1096;&#1072;&#1090;&#1080;
            &#1111;&#1093; &#1073;&#1077;&#1079;
            &#1085;&#1072;&#1075;&#1083;&#1103;&#1076;&#1091;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1077;&#1089;&#1090;&#1080;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
            (&#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1100;&#1085;&#1091;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1085;&#1077;&#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1100;&#1085;&#1091;)
            &#1079;&#1072; &#1089;&#1074;&#1086;&#1111; &#1076;&#1110;&#1111;
            &#1095;&#1080; &#1076;&#1110;&#1111; &#1086;&#1089;&#1110;&#1073;,
            &#1079;&#1072; &#1103;&#1082;&#1080;&#1093;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;,
            &#1087;&#1110;&#1076; &#1095;&#1072;&#1089;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111; &#1090;&#1072;
            &#1079;&#1072; &#1096;&#1082;&#1086;&#1076;&#1091;,
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1091;
            &#1084;&#1072;&#1081;&#1085;&#1091;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1093;
            &#1086;&#1089;&#1110;&#1073;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1095;&#1080; &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;&#1080;,
            &#1079;&#1072; &#1103;&#1082;&#1080;&#1093; &#1074;&#1110;&#1085;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1087;&#1088;&#1086;&#1103;&#1074;&#1083;&#1103;&#1090;&#1080;
            &#1087;&#1086;&#1074;&#1072;&#1075;&#1091; &#1076;&#1086;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;,
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1055;&#1088;&#1072;&#1094;&#1110;&#1074;&#1085;&#1080;&#1082;&#1110;&#1074;
            &#1110; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1087;&#1088;&#1080;
            &#1086;&#1092;&#1086;&#1088;&#1084;&#1083;&#1077;&#1085;&#1085;&#1110;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1085;&#1072; &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1085;&#1072;&#1076;&#1072;&#1090;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1102;
            &#1072;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1091;
            &#1110; &#1087;&#1088;&#1072;&#1074;&#1076;&#1080;&#1074;&#1091;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1091;
            &#1076;&#1083;&#1103;
            &#1079;&#1076;&#1110;&#1081;&#1089;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1079;&#1074;&rsquo;&#1103;&#1079;&#1082;&#1091; &#1079;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084; &#1074;
            &#1088;&#1072;&#1084;&#1082;&#1072;&#1093;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1072; &#1089;&#1072;&#1084;&#1077;:
            &#1087;&#1088;&#1110;&#1079;&#1074;&#1080;&#1097;&#1077;
            &#1090;&#1072; &#1110;&#1084;&rsquo;&#1103;,
            &#1072;&#1076;&#1088;&#1077;&#1089;&#1091;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1111;
            &#1087;&#1086;&#1096;&#1090;&#1080;,
            &#1082;&#1086;&#1085;&#1090;&#1072;&#1082;&#1090;&#1085;&#1080;&#1081;
            &#1085;&#1086;&#1084;&#1077;&#1088;
            &#1090;&#1077;&#1083;&#1077;&#1092;&#1086;&#1085;&#1091;
            &#1090;&#1072; &#1110;&#1085;&#1096;&#1077; &#1085;&#1072;
            &#1074;&#1080;&#1084;&#1086;&#1075;&#1091;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1077;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1084;
            &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1086;&#1084;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087; &#1076;&#1086;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1110;&#1074;
            &#1082;&#1091;&#1088;&#1089;&#1110;&#1074;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;,
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1087;&#1086;&#1074;&#1085;&#1091;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1087;&#1072;&#1088;&#1086;&#1083;&#1110;&#1074;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1091; &#1076;&#1086;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1075;&#1086;
            &#1082;&#1072;&#1073;&#1110;&#1085;&#1077;&#1090;&#1091; &#1074;
            &#1079;&#1072;&#1082;&#1088;&#1080;&#1090;&#1086;&#1084;&#1091;
            &#1088;&#1086;&#1079;&#1076;&#1110;&#1083;&#1110;
            &#1057;&#1072;&#1081;&#1090;&#1091; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1074;&#1077;&#1073;-&#1089;&#1072;&#1081;&#1090;&#1091;,
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1080;
            &#1090;&#1072;
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1085;&#1086;&#1075;&#1086;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
            (&#1055;&#1054;);
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1077;
            &#1082;&#1086;&#1087;&#1110;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1110; &#1085;&#1077;
            &#1087;&#1086;&#1096;&#1080;&#1088;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1084;
            &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1086;&#1084;
            (&#1085;&#1077;
            &#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1074;&#1072;&#1090;&#1080;,
            &#1085;&#1077;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1085;&#1072; &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1088;&#1077;&#1089;&#1091;&#1088;&#1089;&#1072;&#1093; &#1074;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;,
            &#1085;&#1077;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1072;&#1073;&#1086; &#1085;&#1077;
            &#1087;&#1077;&#1088;&#1077;&#1087;&#1088;&#1086;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084;)
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1080;,
            &#1097;&#1086;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1072;&#1090;&#1100;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1102;
            &#1090;&#1072;
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1110; &#1074;
            &#1093;&#1086;&#1076;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;, &#1085;&#1077;
            &#1089;&#1090;&#1074;&#1086;&#1088;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1085;&#1072; &#1111;&#1093;
            &#1086;&#1089;&#1085;&#1086;&#1074;&#1110;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1081;&#1085;&#1110;
            &#1087;&#1088;&#1086;&#1076;&#1091;&#1082;&#1090;&#1080; &#1079;
            &#1084;&#1077;&#1090;&#1086;&#1102;
            &#1074;&#1080;&#1083;&#1091;&#1095;&#1077;&#1085;&#1085;&#1103;
            &#1082;&#1086;&#1084;&#1077;&#1088;&#1094;&#1110;&#1081;&#1085;&#1086;&#1075;&#1086;
            &#1087;&#1088;&#1080;&#1073;&#1091;&#1090;&#1082;&#1091;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078; &#1085;&#1077;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1086;&#1074;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1094;&#1102;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1084;
            &#1110;&#1085;&#1096;&#1080;&#1084;
            &#1095;&#1080;&#1085;&#1086;&#1084;, &#1082;&#1088;&#1110;&#1084;
            &#1103;&#1082; &#1076;&#1083;&#1103;
            &#1086;&#1089;&#1086;&#1073;&#1080;&#1089;&#1090;&#1086;&#1075;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;.
            &#1055;&#1088;&#1080;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1110;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1083;&#1086;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;
            &#1076;&#1086; &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1072;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c1">
          <span className="c0">
            3.4. &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1084;&#1072;&#1108; &#1087;&#1088;&#1072;&#1074;&#1086;:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
            &#1074;&#1110;&#1076;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1079; &#1087;&#1080;&#1090;&#1072;&#1085;&#1100;, &#1097;&#1086;
            &#1089;&#1090;&#1086;&#1089;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1086;&#1088;&#1075;&#1072;&#1085;&#1110;&#1079;&#1072;&#1094;&#1110;&#1111;
            &#1110;
            &#1079;&#1072;&#1073;&#1077;&#1079;&#1087;&#1077;&#1095;&#1077;&#1085;&#1085;&#1103;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;&#1075;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1080;&#1093;
            &#1086;&#1092;&#1077;&#1088;&#1090;&#1086;&#1102;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            -
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1102;&#1074;&#1072;&#1090;&#1080;
            &#1075;&#1088;&#1072;&#1092;&#1110;&#1082;
            &#1074;&#1110;&#1076;&#1074;&#1110;&#1076;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;
            &#1079;&#1075;&#1110;&#1076;&#1085;&#1086; &#1087;.2.5.
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;&#1080;&#1084;&#1072;&#1075;&#1072;&#1090;&#1080;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1086;&#1075;&#1086;
            &#1089;&#1090;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1090;&#1072; &#1087;&#1086;&#1074;&#1072;&#1075;&#1080;
            &#1076;&#1086; &#1089;&#1077;&#1073;&#1077; &#1079;
            &#1073;&#1086;&#1082;&#1091;
            &#1087;&#1088;&#1077;&#1076;&#1089;&#1090;&#1072;&#1074;&#1085;&#1080;&#1082;&#1110;&#1074;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1103;&#1090;&#1080;
            &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1110;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;, &#1097;&#1086;
            &#1087;&#1110;&#1076;&#1083;&#1103;&#1075;&#1072;&#1102;&#1090;&#1100;
            &#1076;&#1086;&#1076;&#1072;&#1090;&#1082;&#1086;&#1074;&#1110;&#1081;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1110;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;
            &#1076;&#1086;&#1089;&#1091;&#1076;&#1086;&#1074;&#1077;
            &#1090;&#1072;
            &#1084;&#1072;&#1082;&#1089;&#1080;&#1084;&#1072;&#1083;&#1100;&#1085;&#1086;
            &#1087;&#1086;&#1074;&#1085;&#1077;
            &#1074;&#1088;&#1077;&#1075;&#1091;&#1083;&#1102;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1089;&#1087;&#1110;&#1088;&#1085;&#1080;&#1093;
            &#1087;&#1080;&#1090;&#1072;&#1085;&#1100;, &#1103;&#1082;&#1110;
            &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1074;&#1080;&#1085;&#1080;&#1082;&#1085;&#1091;&#1090;&#1080;
            &#1087;&#1110;&#1076; &#1095;&#1072;&#1089;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1110;
            &#1074;&#1078;&#1080;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1074;&#1089;&#1110;&#1093;
            &#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1080;&#1093;
            &#1076;&#1086;&#1089;&#1091;&#1076;&#1086;&#1074;&#1080;&#1093;
            &#1079;&#1072;&#1093;&#1086;&#1076;&#1110;&#1074;
            &#1076;&#1083;&#1103;
            &#1074;&#1080;&#1088;&#1110;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1080;&#1093;
            &#1087;&#1080;&#1090;&#1072;&#1085;&#1100;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1085;&#1072;
            &#1087;&#1110;&#1076;&#1090;&#1088;&#1080;&#1084;&#1082;&#1091;
            &#1089;&#1074;&#1086;&#1111;&#1093;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1085;&#1080;&#1093;
            &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1110;&#1074;
            &#1090;&#1072;
            &#1087;&#1088;&#1072;&#1074;&#1086;&#1084;&#1110;&#1088;&#1085;&#1080;&#1093;
            &#1074;&#1080;&#1084;&#1086;&#1075;;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1087;&#1088;&#1080;&#1081;&#1084;&#1072;&#1090;&#1080;
            &#1091;&#1095;&#1072;&#1089;&#1090;&#1100; &#1091;
            &#1087;&#1088;&#1086;&#1075;&#1088;&#1072;&#1084;&#1072;&#1093;
            &#1083;&#1086;&#1103;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1076;&#1083;&#1103;
            &#1082;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;, &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091; &#1111;&#1093;
            &#1076;&#1110;&#1111;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            4. &#1042;&#1040;&#1056;&#1058;&#1030;&#1057;&#1058;&#1068;
            &#1055;&#1054;&#1057;&#1051;&#1059;&#1043; &#1030;
            &#1055;&#1054;&#1056;&#1071;&#1044;&#1054;&#1050;
            &#1056;&#1054;&#1047;&#1056;&#1040;&#1061;&#1059;&#1053;&#1050;&#1030;&#1042;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.1. &#1042;&#1072;&#1088;&#1090;&#1110;&#1089;&#1090;&#1100;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;, &#1097;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;,
            &#1110;
            &#1076;&#1086;&#1089;&#1090;&#1091;&#1087;&#1085;&#1080;&#1093;
            &#1076;&#1086;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;,
            &#1074;&#1082;&#1072;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110; &#1110;
            &#1084;&#1086;&#1078;&#1077; &#1073;&#1091;&#1090;&#1080;
            &#1079;&#1084;&#1110;&#1085;&#1077;&#1085;&#1072;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1085;&#1072; &#1089;&#1074;&#1110;&#1081;
            &#1088;&#1086;&#1079;&#1089;&#1091;&#1076; &#1074;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1081;
            &#1095;&#1072;&#1089; &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;.
            &#1047;&#1084;&#1110;&#1085;&#1077;&#1085;&#1072;
            &#1074;&#1072;&#1088;&#1090;&#1110;&#1089;&#1090;&#1100;
            &#1076;&#1110;&#1108; &#1079;
            &#1084;&#1086;&#1084;&#1077;&#1085;&#1090;&#1091;
            &#1086;&#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1110;
            &#1087;&#1086;&#1096;&#1080;&#1088;&#1102;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1090;&#1110;&#1083;&#1100;&#1082;&#1080; &#1085;&#1072;
            &#1090;&#1110; &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;,
            &#1103;&#1082;&#1110;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1103;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1087;&#1110;&#1089;&#1083;&#1103;
            &#1086;&#1087;&#1091;&#1073;&#1083;&#1110;&#1082;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1085;&#1086;&#1074;&#1086;&#1111;
            &#1074;&#1072;&#1088;&#1090;&#1086;&#1089;&#1090;&#1110;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.2. &#1054;&#1087;&#1083;&#1072;&#1090;&#1072;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1087;&#1088;&#1086;&#1074;&#1086;&#1076;&#1080;&#1090;&#1080;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084; &#1074;
            &#1092;&#1086;&#1088;&#1084;&#1110;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1074; &#1088;&#1086;&#1079;&#1084;&#1110;&#1088;&#1110; 100%
            &#1074;&#1110;&#1076;
            &#1074;&#1072;&#1088;&#1090;&#1086;&#1089;&#1090;&#1110;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1091;
            &#1073;&#1077;&#1079;&#1075;&#1086;&#1090;&#1110;&#1074;&#1082;&#1086;&#1074;&#1110;&#1081;
            &#1092;&#1086;&#1088;&#1084;&#1110; &#1079;&#1072;
            &#1073;&#1072;&#1085;&#1082;&#1110;&#1074;&#1089;&#1100;&#1082;&#1080;&#1084;&#1080;
            &#1088;&#1077;&#1082;&#1074;&#1110;&#1079;&#1080;&#1090;&#1072;&#1084;&#1080;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1080;&#1084;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1072;&#1073;&#1086; &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1087;&#1083;&#1072;&#1090;&#1110;&#1078;&#1085;&#1091;
            &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1091;,
            &#1074;&#1087;&#1088;&#1086;&#1074;&#1072;&#1076;&#1078;&#1077;&#1085;&#1091;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;.
            &#1057;&#1087;&#1086;&#1089;&#1110;&#1073;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;.{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.3. &#1052;&#1086;&#1084;&#1077;&#1085;&#1090;&#1086;&#1084;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072;&#1076;&#1093;&#1086;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;
            &#1075;&#1088;&#1086;&#1096;&#1086;&#1074;&#1080;&#1093;
            &#1082;&#1086;&#1096;&#1090;&#1110;&#1074; &#1085;&#1072;
            &nbsp;&#1088;&#1072;&#1093;&#1091;&#1085;&#1086;&#1082;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.4.
            &#1055;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;
            &#1086;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1076;&#1072;&#1085;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1108;
            &#1082;&#1074;&#1080;&#1090;&#1072;&#1085;&#1094;&#1110;&#1103;
            &#1072;&#1073;&#1086; &#1110;&#1085;&#1096;&#1080;&#1081;
            &#1086;&#1092;&#1110;&#1094;&#1110;&#1081;&#1085;&#1080;&#1081;
            &#1088;&#1086;&#1079;&#1088;&#1072;&#1093;&#1091;&#1085;&#1082;&#1086;&#1074;&#1080;&#1081;
            &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;,
            &#1097;&#1086;
            &#1085;&#1072;&#1076;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1091;
            &#1087;&#1088;&#1080; &#1086;&#1087;&#1083;&#1072;&#1090;&#1110;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1091;
            &#1073;&#1072;&#1085;&#1082;&#1110;&#1074;&#1089;&#1100;&#1082;&#1091;
            &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1091;
            &#1072;&#1073;&#1086;
            &#1086;&#1092;&#1110;&#1094;&#1110;&#1081;&#1085;&#1091;
            &#1087;&#1083;&#1072;&#1090;&#1110;&#1078;&#1085;&#1091;
            &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.5. &#1042;&#1089;&#1110;
            &#1074;&#1080;&#1090;&#1088;&#1072;&#1090;&#1080;
            &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1110;
            &#1079;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1095;&#1077;&#1102;
            &#1075;&#1088;&#1086;&#1096;&#1086;&#1074;&#1080;&#1093;
            &#1082;&#1086;&#1096;&#1090;&#1110;&#1074;,
            &#1074;&#1082;&#1083;&#1102;&#1095;&#1072;&#1102;&#1095;&#1080;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1073;&#1072;&#1085;&#1082;&#1110;&#1074;&#1089;&#1100;&#1082;&#1086;&#1111;
            &#1091;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1080;
            &#1072;&#1073;&#1086;
            &#1087;&#1083;&#1072;&#1090;&#1110;&#1078;&#1085;&#1086;&#1111;
            &#1089;&#1080;&#1089;&#1090;&#1077;&#1084;&#1080;,
            &#1087;&#1086;&#1082;&#1083;&#1072;&#1076;&#1072;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.6. &#1053;&#1072;&#1076;&#1072;&#1085;&#1110;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080; &#1108;
            &#1110;&#1085;&#1076;&#1080;&#1074;&#1110;&#1076;&#1091;&#1072;&#1083;&#1100;&#1085;&#1080;&#1084;
            &#1079;&#1072;&#1084;&#1086;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;&#1084;
            &#1076;&#1083;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;, &#1072;
            &#1090;&#1086;&#1084;&#1091;
            &#1089;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1075;&#1088;&#1086;&#1096;&#1086;&#1074;&#1110;
            &#1082;&#1086;&#1096;&#1090;&#1080; &#1085;&#1077;
            &#1087;&#1110;&#1076;&#1083;&#1103;&#1075;&#1072;&#1102;&#1090;&#1100;
            &#1087;&#1086;&#1074;&#1077;&#1088;&#1085;&#1077;&#1085;&#1085;&#1102;
            &#1090;&#1072;
            &#1086;&#1087;&#1083;&#1072;&#1095;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084; &#1091;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1084;&#1091;
            &#1086;&#1073;&#1089;&#1103;&#1079;&#1110;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            4.7. &#1059; &#1088;&#1072;&#1079;&#1110;
            &#1087;&#1086;&#1074;&#1085;&#1086;&#1111; &#1072;&#1073;&#1086;
            &#1095;&#1072;&#1089;&#1090;&#1082;&#1086;&#1074;&#1086;&#1111;
            &#1085;&#1077;&#1089;&#1087;&#1083;&#1072;&#1090;&#1080;
            &#1074;&#1072;&#1088;&#1090;&#1086;&#1089;&#1090;&#1110;
            &#1087;&#1086;&#1089;&#1083;&#1091;&#1075;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;,
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1084;&#1072;&#1108; &#1087;&#1088;&#1072;&#1074;&#1086;
            &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1080;&#1090;&#1080;&#1089;&#1100;
            &#1074;&#1110;&#1076; &#1111;&#1093;
            &#1085;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;.
            &#1055;&#1088;&#1080; &#1094;&#1100;&#1086;&#1084;&#1091;
            &#1074;&#1078;&#1077;
            &#1089;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1082;&#1086;&#1096;&#1090;&#1080; &#1108;
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1090;&#1082;&#1086;&#1084;
            &#1079;&#1072;
            &#1076;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1110;
            &#1087;&#1086;&#1074;&#1077;&#1088;&#1085;&#1077;&#1085;&#1085;&#1102;
            &#1085;&#1077;
            &#1087;&#1110;&#1076;&#1083;&#1103;&#1075;&#1072;&#1102;&#1090;&#1100;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            5. &#1060;&#1054;&#1056;&#1057; &mdash;
            &#1052;&#1040;&#1046;&#1054;&#1056;&#1053;&#1030;
            &#1054;&#1041;&#1057;&#1058;&#1040;&#1042;&#1048;&#1053;&#1048;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.1. &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;
            &#1079;&#1074;&#1110;&#1083;&#1100;&#1085;&#1103;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1110;&#1076;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;&#1072;
            &#1095;&#1072;&#1089;&#1090;&#1082;&#1086;&#1074;&#1077;
            &#1095;&#1080; &#1087;&#1086;&#1074;&#1085;&#1077;
            &#1085;&#1077;&#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1072;&#1073;&#1086;
            &#1085;&#1077;&#1085;&#1072;&#1083;&#1077;&#1078;&#1085;&#1077;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;
            &#1079;&#1072;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1103;&#1082;&#1097;&#1086; &#1074;&#1086;&#1085;&#1080; &#1108;
            &#1085;&#1072;&#1089;&#1083;&#1110;&#1076;&#1082;&#1086;&#1084;
            &#1085;&#1077;&#1087;&#1077;&#1088;&#1077;&#1073;&#1086;&#1088;&#1085;&#1086;&#1111;
            &#1089;&#1080;&#1083;&#1080;
            (&#1087;&#1086;&#1078;&#1077;&#1078;&#1110;,
            &#1087;&#1086;&#1074;&#1077;&#1085;&#1110;,
            &#1079;&#1077;&#1084;&#1083;&#1077;&#1090;&#1088;&#1091;&#1089;&#1091;,
            &#1089;&#1090;&#1080;&#1093;&#1110;&#1081;&#1085;&#1086;&#1075;&#1086;
            &#1083;&#1080;&#1093;&#1072;,
            &#1077;&#1087;&#1110;&#1076;&#1077;&#1084;&#1110;&#1111;,
            &#1074;&#1110;&#1081;&#1089;&#1100;&#1082;&#1086;&#1074;&#1080;&#1093;
            &#1076;&#1110;&#1081; &#1110; &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
            &#1085;&#1077;&#1087;&#1077;&#1088;&#1077;&#1073;&#1086;&#1088;&#1085;&#1086;&#1111;
            &#1089;&#1080;&#1083;&#1080;) (&#1076;&#1072;&#1083;&#1110; &mdash;
            &laquo;&#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&raquo;),
            &#1110; &#1103;&#1082;&#1097;&#1086; &#1094;&#1110;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;&#1080;
            &#1073;&#1077;&#1079;&#1087;&#1086;&#1089;&#1077;&#1088;&#1077;&#1076;&#1085;&#1100;&#1086;
            &#1074;&#1087;&#1083;&#1080;&#1085;&#1091;&#1083;&#1080;
            &#1085;&#1072;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;,
            &#1090;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1086;&#1076;&#1086;&#1074;&#1078;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1089;&#1090;&#1088;&#1086;&#1082;,
            &#1087;&#1088;&#1086;&#1090;&#1103;&#1075;&#1086;&#1084;
            &#1103;&#1082;&#1086;&#1075;&#1086;
            &#1076;&#1110;&#1103;&#1083;&#1080; &#1094;&#1110;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.2. &#1071;&#1082;&#1097;&#1086;
            &#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&#1085;&#1110;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;&#1080;
            &#1073;&#1091;&#1076;&#1091;&#1090;&#1100;
            &#1087;&#1088;&#1086;&#1076;&#1086;&#1074;&#1078;&#1091;&#1074;&#1072;&#1090;&#1080;&#1089;&#1103;
            &#1073;&#1110;&#1083;&#1100;&#1096;&#1077;
            &#1086;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1084;&#1110;&#1089;&#1103;&#1094;&#1103;, &#1090;&#1086;
            &#1082;&#1086;&#1078;&#1085;&#1072; &#1079;&#1110;
            &#1057;&#1090;&#1086;&#1088;&#1110;&#1085;
            &#1073;&#1091;&#1076;&#1077; &#1084;&#1072;&#1090;&#1080;
            &#1087;&#1088;&#1072;&#1074;&#1086;
            &#1074;&#1110;&#1076;&#1084;&#1086;&#1074;&#1080;&#1090;&#1080;&#1089;&#1103;
            &#1074;&#1110;&#1076;
            &#1087;&#1086;&#1076;&#1072;&#1083;&#1100;&#1096;&#1086;&#1075;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;
            &#1079;&#1072;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1110; &#1074; &#1094;&#1100;&#1086;&#1084;&#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1077;&#1085;&#1080;&#1084;
            &#1091; &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1076;&#1086;&#1089;&#1103;&#1075;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;
            &#1079;&#1075;&#1086;&#1076;&#1080; &#1087;&#1088;&#1086;
            &#1087;&#1088;&#1072;&#1074;&#1086;&#1074;&#1110;
            &#1085;&#1072;&#1089;&#1083;&#1110;&#1076;&#1082;&#1080;
            &#1079;&#1075;&#1110;&#1076;&#1085;&#1086;
            &#1091;&#1084;&#1086;&#1074;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.3. &#1059; &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1085;&#1077;&#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086;&#1089;&#1090;&#1110;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1100;
            &#1079;&#1072;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;
            &#1074; &#1085;&#1072;&#1089;&#1083;&#1110;&#1076;&#1086;&#1082;
            &#1085;&#1072;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&#1085;&#1080;&#1093;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1087;&#1086;&#1074;&#1080;&#1085;&#1077;&#1085; &#1085;&#1077;
            &#1087;&#1110;&#1079;&#1085;&#1110;&#1096;&#1077; 5
            (&#1087;&rsquo;&#1103;&#1090;&#1080;)
            &#1088;&#1086;&#1073;&#1086;&#1095;&#1080;&#1093;
            &#1076;&#1085;&#1110;&#1074;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1080;&#1090;&#1080;
            &#1087;&#1088;&#1086; &#1094;&#1077;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1111;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1085;&#1072; &#1057;&#1072;&#1081;&#1090;&#1110;
            &#1072;&#1073;&#1086;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1110;
            (&#1089;&#1086;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1110;&#1081;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1110;), &#1097;&#1086;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1080;&#1090;&#1100;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1102;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.4. &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;, &#1091;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1091;
            &#1085;&#1077;&#1084;&#1086;&#1078;&#1083;&#1080;&#1074;&#1086;&#1089;&#1090;&#1110;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1090;&#1080;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1085;&#1103;
            &#1079;&#1072;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1087;&#1086;&#1074;&#1080;&#1085;&#1077;&#1085;
            &#1087;&#1080;&#1089;&#1100;&#1084;&#1086;&#1074;&#1086;
            &#1085;&#1077; &#1087;&#1110;&#1079;&#1085;&#1110;&#1096;&#1077; 5
            (&#1087;&rsquo;&#1103;&#1090;&#1080;)
            &#1088;&#1086;&#1073;&#1086;&#1095;&#1080;&#1093;
            &#1076;&#1085;&#1110;&#1074;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1080;&#1090;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&#1091;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1085;&#1072;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1075;&#1086;
            &#1077;&#1083;&#1077;&#1082;&#1090;&#1088;&#1086;&#1085;&#1085;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1095;&#1077;&#1088;&#1077;&#1079;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1091;
            (&#1089;&#1086;&#1094;&#1110;&#1072;&#1083;&#1100;&#1085;&#1091;
            &#1084;&#1077;&#1088;&#1077;&#1078;&#1091;), &#1097;&#1086;
            &#1085;&#1072;&#1083;&#1077;&#1078;&#1080;&#1090;&#1100;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1102;.{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.5. &#1053;&#1072;&#1103;&#1074;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&#1085;&#1080;&#1093;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
            &#1084;&#1072;&#1108; &#1073;&#1091;&#1090;&#1080;
            &#1087;&#1110;&#1076;&#1090;&#1074;&#1077;&#1088;&#1076;&#1078;&#1077;&#1085;&#1072;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1080;&#1084;&#1080;
            &#1076;&#1086;&#1082;&#1091;&#1084;&#1077;&#1085;&#1090;&#1072;&#1084;&#1080;
            &#1058;&#1086;&#1088;&#1075;&#1086;&#1074;&#1086;-&#1087;&#1088;&#1086;&#1084;&#1080;&#1089;&#1083;&#1086;&#1074;&#1086;&#1111;
            &#1087;&#1072;&#1083;&#1072;&#1090;&#1080; &#1072;&#1073;&#1086;
            &#1110;&#1085;&#1096;&#1086;&#1075;&#1086;
            &#1091;&#1087;&#1086;&#1074;&#1085;&#1086;&#1074;&#1072;&#1078;&#1077;&#1085;&#1086;&#1075;&#1086;
            &#1086;&#1088;&#1075;&#1072;&#1085;&#1091;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            5.6.
            &#1053;&#1077;&#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1072;&#1073;&#1086;
            &#1085;&#1077;&#1089;&#1074;&#1086;&#1108;&#1095;&#1072;&#1089;&#1085;&#1077;
            &#1087;&#1086;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1086;
            &#1085;&#1072;&#1089;&#1090;&#1072;&#1085;&#1085;&#1103;
            &#1095;&#1080;
            &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1092;&#1086;&#1088;&#1089;-&#1084;&#1072;&#1078;&#1086;&#1088;&#1085;&#1080;&#1093;
            &#1086;&#1073;&#1089;&#1090;&#1072;&#1074;&#1080;&#1085;
            &#1087;&#1086;&#1079;&#1073;&#1072;&#1074;&#1083;&#1103;&#1108;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1091;
            &#1087;&#1088;&#1072;&#1074;&#1072; &#1085;&#1072;
            &#1085;&#1080;&#1093;
            &#1087;&#1086;&#1089;&#1080;&#1083;&#1072;&#1090;&#1080;&#1089;&#1103;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            6.
            &#1050;&#1054;&#1053;&#1060;&#1030;&#1044;&#1045;&#1053;&#1062;&#1030;&#1049;&#1053;&#1030;&#1057;&#1058;&#1068;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            6.1. &#1059;&#1089;&#1103;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
            &#1097;&#1086;
            &#1089;&#1090;&#1086;&#1089;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1091;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1085;&#1103;
            &#1110;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1108;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;.
            &#1053;&#1077;
            &#1074;&#1074;&#1072;&#1078;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1102;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1103;,
            &#1103;&#1082;&#1091;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;
            &#1086;&#1092;&#1110;&#1094;&#1110;&#1081;&#1085;&#1086;
            &#1086;&#1087;&#1088;&#1080;&#1083;&#1102;&#1076;&#1085;&#1102;&#1102;&#1090;&#1100;
            &#1079;&#1075;&#1110;&#1076;&#1085;&#1086; &#1079;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            6.2. &#1055;&#1088;&#1086;&#1090;&#1103;&#1075;&#1086;&#1084;
            &#1089;&#1090;&#1088;&#1086;&#1082;&#1091; &#1076;&#1110;&#1111;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1087;&#1110;&#1089;&#1083;&#1103; &#1081;&#1086;&#1075;&#1086;
            &#1087;&#1088;&#1080;&#1087;&#1080;&#1085;&#1077;&#1085;&#1085;&#1103;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080; &#1085;&#1077;
            &#1087;&#1086;&#1074;&#1080;&#1085;&#1085;&#1110;
            &#1085;&#1072;&#1076;&#1072;&#1074;&#1072;&#1090;&#1080;
            &#1090;&#1088;&#1077;&#1090;&#1110;&#1084;
            &#1086;&#1089;&#1086;&#1073;&#1072;&#1084; &#1072;&#1073;&#1086;
            &#1088;&#1086;&#1079;&#1075;&#1086;&#1083;&#1086;&#1096;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1110;&#1085;&#1096;&#1080;&#1084;
            &#1089;&#1087;&#1086;&#1089;&#1086;&#1073;&#1086;&#1084;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1091;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1102;,
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1091; &#1074;
            &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1110;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;
            &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            6.3. &#1047;&#1072;
            &#1088;&#1086;&#1079;&#1075;&#1086;&#1083;&#1086;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1082;&#1086;&#1085;&#1092;&#1110;&#1076;&#1077;&#1085;&#1094;&#1110;&#1081;&#1085;&#1086;&#1111;
            &#1110;&#1085;&#1092;&#1086;&#1088;&#1084;&#1072;&#1094;&#1110;&#1111;
            &#1074;&#1080;&#1085;&#1085;&#1072;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;
            &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1079;&#1075;&#1110;&#1076;&#1085;&#1086; &#1079;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;
            &#1079;&#1086;&#1073;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1110;&#1076;&#1096;&#1082;&#1086;&#1076;&#1091;&#1074;&#1072;&#1090;&#1080;
            &#1079;&#1072;&#1074;&#1076;&#1072;&#1085;&#1110;
            &#1094;&#1080;&#1084; &#1079;&#1073;&#1080;&#1090;&#1082;&#1080;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            7. &#1042;&#1048;&#1056;&#1030;&#1064;&#1045;&#1053;&#1053;&#1071;
            &#1057;&#1055;&#1054;&#1056;&#1030;&#1042;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            7.1. &#1042;&#1089;&#1110;
            &#1088;&#1086;&#1079;&#1073;&#1110;&#1078;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1090;&#1072; &#1089;&#1087;&#1086;&#1088;&#1080;,
            &#1087;&#1086;&#1074;&rsquo;&#1103;&#1079;&#1072;&#1085;&#1110;
            &#1079;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1091;&#1084;&#1086;&#1074;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1074;&#1080;&#1088;&#1110;&#1096;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;
            &#1096;&#1083;&#1103;&#1093;&#1086;&#1084;
            &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1110;&#1074;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            7.2. &#1059; &#1088;&#1072;&#1079;&#1110;
            &#1103;&#1082;&#1097;&#1086;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080; &#1074;
            &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1110;
            &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1110;&#1074;
            &#1085;&#1077; &#1079;&#1084;&#1086;&#1075;&#1083;&#1080;
            &#1076;&#1110;&#1081;&#1090;&#1080;
            &#1074;&#1079;&#1072;&#1108;&#1084;&#1085;&#1086;&#1111;
            &#1079;&#1075;&#1086;&#1076;&#1080;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078; &#1091;
            &#1088;&#1072;&#1079;&#1110; &#1103;&#1082;&#1097;&#1086;
            &#1086;&#1076;&#1085;&#1072; &#1079;
            &#1057;&#1090;&#1086;&#1088;&#1110;&#1085;
            &#1091;&#1093;&#1080;&#1083;&#1103;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1074;&#1110;&#1076;
            &#1087;&#1088;&#1086;&#1074;&#1077;&#1076;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1077;&#1088;&#1077;&#1075;&#1086;&#1074;&#1086;&#1088;&#1110;&#1074;,
            &#1089;&#1087;&#1110;&#1088;
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1088;&#1086;&#1079;&#1075;&#1083;&#1103;&#1076;
            &#1089;&#1091;&#1076;&#1091; &#1074;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;,
            &#1074;&#1089;&#1090;&#1072;&#1085;&#1086;&#1074;&#1083;&#1077;&#1085;&#1086;&#1084;&#1091;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.{" "}
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            8. &#1057;&#1058;&#1056;&#1054;&#1050; &#1044;&#1030;&#1031;
            &#1044;&#1054;&#1043;&#1054;&#1042;&#1054;&#1056;&#1059;
            &#1058;&#1040; &#1055;&#1054;&#1056;&#1071;&#1044;&#1054;&#1050;
            &#1056;&#1054;&#1047;&#1030;&#1056;&#1042;&#1040;&#1053;&#1053;&#1071;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            8.1 &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1084;&#1086;&#1078;&#1077; &#1073;&#1091;&#1090;&#1080;
            &#1088;&#1086;&#1079;&#1110;&#1088;&#1074;&#1072;&#1085;&#1080;&#1081;
            &#1084;&#1110;&#1078;
            &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1072;&#1084;&#1080;
            &#1074; &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091;,
            &#1087;&#1077;&#1088;&#1077;&#1076;&#1073;&#1072;&#1095;&#1077;&#1085;&#1086;&#1084;&#1091;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;, &#1072;
            &#1090;&#1072;&#1082;&#1086;&#1078;:
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091; &#1079;
            &#1110;&#1085;&#1110;&#1094;&#1110;&#1072;&#1090;&#1080;&#1074;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1074; &#1088;&#1072;&#1079;&#1110;, &#1103;&#1082;&#1097;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1073;&#1091;&#1083;&#1086;
            &#1076;&#1086;&#1087;&#1091;&#1097;&#1077;&#1085;&#1086;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1091;&#1084;&#1086;&#1074;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;,
            &#1087;&#1088;&#1072;&#1074;
            &#1110;&#1085;&#1090;&#1077;&#1083;&#1077;&#1082;&#1090;&#1091;&#1072;&#1083;&#1100;&#1085;&#1086;&#1111;
            &#1074;&#1083;&#1072;&#1089;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1072;&#1073;&#1086;
            &#1089;&#1091;&#1084;&#1110;&#1078;&#1085;&#1080;&#1093;
            &#1087;&#1088;&#1072;&#1074;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1085;&#1072;
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1110;
            &#1111;&#1084; &#1082;&#1091;&#1088;&#1089;&#1080; &#1090;&#1072;
            &#1089;&#1082;&#1083;&#1072;&#1076;&#1086;&#1074;&#1110;
            &#1111;&#1093;
            &#1084;&#1072;&#1090;&#1077;&#1088;&#1110;&#1072;&#1083;&#1110;&#1074;.
            &#1055;&#1088;&#1080; &#1094;&#1100;&#1086;&#1084;&#1091;,
            &#1082;&#1086;&#1096;&#1090;&#1080;,
            &#1089;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1079;&#1072; &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1087;&#1086;&#1074;&#1077;&#1088;&#1085;&#1077;&#1085;&#1085;&#1102;
            &#1085;&#1077;
            &#1087;&#1110;&#1076;&#1083;&#1103;&#1075;&#1072;&#1102;&#1090;&#1100;
            &#1110; &#1108;
            &#1085;&#1077;&#1091;&#1089;&#1090;&#1086;&#1081;&#1082;&#1086;&#1102;
            (&#1096;&#1090;&#1088;&#1072;&#1092;&#1086;&#1084;) &#1079;&#1072;
            &#1076;&#1110;&#1111;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            - &#1074;
            &#1086;&#1076;&#1085;&#1086;&#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1085;&#1100;&#1086;&#1084;&#1091;
            &#1087;&#1086;&#1088;&#1103;&#1076;&#1082;&#1091; &#1079;
            &#1110;&#1085;&#1110;&#1094;&#1110;&#1072;&#1090;&#1080;&#1074;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1074; &#1088;&#1072;&#1079;&#1110;, &#1103;&#1082;&#1097;&#1086;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1073;&#1091;&#1083;&#1086;
            &#1076;&#1086;&#1087;&#1091;&#1097;&#1077;&#1085;&#1086;
            &#1087;&#1086;&#1088;&#1091;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1087;&#1088;&#1072;&#1074;&#1080;&#1083;
            &#1087;&#1086;&#1074;&#1077;&#1076;&#1110;&#1085;&#1082;&#1080;
            &#1074; &#1087;&#1088;&#1086;&#1094;&#1077;&#1089;&#1110;
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1072; &#1089;&#1072;&#1084;&#1077;:
            &#1088;&#1086;&#1079;&#1087;&#1072;&#1083;&#1102;&#1074;&#1072;&#1085;&#1085;&#1103;
            &#1084;&#1110;&#1078;&#1085;&#1072;&#1094;&#1110;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1090;&#1072;
            &#1084;&#1110;&#1078;&#1088;&#1077;&#1083;&#1110;&#1075;&#1110;&#1081;&#1085;&#1080;&#1093;
            &#1082;&#1086;&#1085;&#1092;&#1083;&#1110;&#1082;&#1090;&#1110;&#1074;,
            &#1089;&#1087;&#1072;&#1084;,
            &#1088;&#1086;&#1079;&#1084;&#1110;&#1097;&#1077;&#1085;&#1085;&#1103;
            &#1088;&#1077;&#1082;&#1083;&#1072;&#1084;&#1080;,
            &#1085;&#1077;&#1094;&#1077;&#1085;&#1079;&#1091;&#1088;&#1085;&#1110;
            &#1074;&#1080;&#1089;&#1083;&#1086;&#1074;&#1083;&#1102;&#1074;&#1072;&#1085;&#1085;&#1103;,
            &#1093;&#1072;&#1084;&#1089;&#1090;&#1074;&#1086;,
            &#1086;&#1073;&#1088;&#1072;&#1079;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;,
            &#1086;&#1073;&#1088;&#1072;&#1079;&#1091;
            &#1110;&#1085;&#1096;&#1080;&#1093;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1110;&#1074;
            &#1072;&#1073;&#1086;
            &#1082;&#1086;&#1088;&#1080;&#1089;&#1090;&#1091;&#1074;&#1072;&#1095;&#1110;&#1074;
            &#1030;&#1085;&#1090;&#1077;&#1088;&#1085;&#1077;&#1090;-&#1087;&#1083;&#1072;&#1090;&#1092;&#1086;&#1088;&#1084;&#1080;.
            &#1055;&#1088;&#1080; &#1094;&#1100;&#1086;&#1084;&#1091;,
            &#1082;&#1086;&#1096;&#1090;&#1080;,
            &#1089;&#1087;&#1083;&#1072;&#1095;&#1077;&#1085;&#1110;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1079;&#1072; &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1087;&#1086;&#1074;&#1077;&#1088;&#1085;&#1077;&#1085;&#1085;&#1102;
            &#1085;&#1077;
            &#1087;&#1110;&#1076;&#1083;&#1103;&#1075;&#1072;&#1102;&#1090;&#1100;
            &#1110; &#1108;
            &#1085;&#1077;&#1091;&#1089;&#1090;&#1086;&#1081;&#1082;&#1086;&#1102;
            (&#1096;&#1090;&#1088;&#1072;&#1092;&#1086;&#1084;) &#1079;&#1072;
            &#1076;&#1110;&#1111;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c3">
          <span className={styles.subheader}>
            9. &#1047;&#1040;&#1050;&#1051;&#1070;&#1063;&#1053;&#1030;
            &#1055;&#1054;&#1051;&#1054;&#1046;&#1045;&#1053;&#1053;&#1071;
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            9.1. &#1055;&#1088;&#1080;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1110;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1089;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;
            &#1082;&#1077;&#1088;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;
            &#1091;&#1084;&#1086;&#1074;&#1072;&#1084;&#1080;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1086;&#1084;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            9.2. &#1062;&#1103; &#1054;&#1092;&#1077;&#1088;&#1090;&#1072;
            &#1090;&#1072; &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088;
            &#1089;&#1082;&#1083;&#1072;&#1076;&#1077;&#1085;&#1110;
            &#1091;&#1082;&#1088;&#1072;&#1111;&#1085;&#1089;&#1100;&#1082;&#1086;&#1102;
            &#1084;&#1086;&#1074;&#1086;&#1102;.{" "}
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            9.3. &#1057;&#1090;&#1086;&#1088;&#1086;&#1085;&#1080;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1102;&#1090;&#1100;&#1089;&#1103;,
            &#1097;&#1086; &#1094;&#1077;&#1081;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1110;&#1088; &#1074;
            &#1094;&#1110;&#1083;&#1086;&#1084;&#1091; &#1090;&#1072;
            &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1072;
            &#1081;&#1086;&#1075;&#1086;
            &#1095;&#1072;&#1089;&#1090;&#1080;&#1085;&#1072;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1108;
            &#1074;&#1110;&#1083;&#1100;&#1085;&#1086;&#1084;&#1091;
            &#1074;&#1086;&#1083;&#1077;&#1074;&#1080;&#1103;&#1074;&#1083;&#1077;&#1085;&#1085;&#1102;
            &#1057;&#1090;&#1086;&#1088;&#1110;&#1085; &#1110; &#1085;&#1077;
            &#1084;&#1086;&#1078;&#1091;&#1090;&#1100;
            &#1073;&#1091;&#1090;&#1080;
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1085;&#1080;&#1084;&#1080;
            &#1090;&#1072;&#1082;&#1080;&#1084;&#1080;, &#1097;&#1086;
            &#1085;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1102;&#1090;&#1100;
            &#1110;&#1085;&#1090;&#1077;&#1088;&#1077;&#1089;&#1072;&#1084;
            &#1086;&#1073;&#1086;&#1093;
            &#1057;&#1090;&#1086;&#1088;&#1110;&#1085;.
          </span>
        </p>
        <p className="c1">
          <span className="c0">
            9.4.
            &#1040;&#1082;&#1094;&#1077;&#1087;&#1090;&#1091;&#1074;&#1072;&#1085;&#1085;&#1103;&#1084;
            &#1094;&#1110;&#1108;&#1111;
            &#1054;&#1092;&#1077;&#1088;&#1090;&#1080;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1091;&#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1083;&#1102;&#1108;
            &#1090;&#1072; &#1079;&#1072;&#1103;&#1074;&#1083;&#1103;&#1108;
            &#1087;&#1088;&#1086; &#1090;&#1077;, &#1097;&#1086;: <br />
            &#1072;) &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1089;&#1074;&#1110;&#1076;&#1086;&#1084;&#1086;
            &#1087;&#1086;&#1075;&#1086;&#1076;&#1078;&#1091;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1085;&#1072; &#1074;&#1089;&#1110;
            &#1091;&#1084;&#1086;&#1074;&#1080;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;; &#1073;)
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1086;&#1079;&#1085;&#1072;&#1081;&#1086;&#1084;&#1083;&#1077;&#1085;&#1080;&#1081;
            &#1079; &#1074;&#1072;&#1088;&#1090;&#1110;&#1089;&#1090;&#1102;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075; &#1079;&#1072;
            &#1094;&#1080;&#1084;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1086;&#1084;,
            &#1103;&#1082;&#1072; &#1081;&#1086;&#1075;&#1086;
            &#1087;&#1086;&#1074;&#1085;&#1110;&#1089;&#1090;&#1102;
            &#1074;&#1083;&#1072;&#1096;&#1090;&#1086;&#1074;&#1091;&#1108;;
            &#1074;) &#1089;&#1090;&#1072;&#1085;
            &#1079;&#1076;&#1086;&#1088;&#1086;&#1074;&#39;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1072;
            &#1076;&#1086;&#1079;&#1074;&#1086;&#1083;&#1103;&#1108;
            &#1081;&#1086;&#1084;&#1091;
            &#1089;&#1087;&#1086;&#1078;&#1080;&#1074;&#1072;&#1090;&#1080;
            &#1055;&#1086;&#1089;&#1083;&#1091;&#1075;&#1080;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1103;
            &#1091;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1076;&#1086; &#1094;&#1100;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;, &#1110;
            &#1097;&#1086; &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1085;&#1077; &#1084;&#1072;&#1108;
            &#1087;&#1088;&#1086;&#1090;&#1080;&#1087;&#1086;&#1082;&#1072;&#1079;&#1072;&#1085;&#1100;
            &#1097;&#1086;&#1076;&#1086;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;
            &#1089;&#1087;&#1086;&#1088;&#1090;&#1086;&#1084; &#1090;&#1072;
            &#1092;&#1110;&#1079;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1085;&#1072;&#1074;&#1072;&#1085;&#1090;&#1072;&#1078;&#1077;&#1085;&#1100;;
            &#1075;)
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1110;&#1089;&#1090;&#1100;
            &#1086;&#1090;&#1088;&#1080;&#1084;&#1072;&#1085;&#1085;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1084;&#1077;&#1076;&#1080;&#1095;&#1085;&#1086;&#1111;
            &#1082;&#1086;&#1085;&#1089;&#1091;&#1083;&#1100;&#1090;&#1072;&#1094;&#1110;&#1111;
            &#1090;&#1072;/&#1072;&#1073;&#1086;
            &#1074;&#1080;&#1089;&#1085;&#1086;&#1074;&#1082;&#1091;
            &#1097;&#1086;&#1076;&#1086;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1072;&#1073;&#1086;
            &#1074;&#1110;&#1076;&#1089;&#1091;&#1090;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1087;&#1088;&#1086;&#1090;&#1080;&#1087;&#1086;&#1082;&#1072;&#1079;&#1072;&#1085;&#1100;
            &#1097;&#1086;&#1076;&#1086;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1091;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;
            &#1074;&#1080;&#1079;&#1085;&#1072;&#1095;&#1072;&#1108;&#1090;&#1100;&#1089;&#1103;
            &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;&#1086;&#1084;
            &#1089;&#1072;&#1084;&#1086;&#1089;&#1090;&#1110;&#1081;&#1085;&#1086;.
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1077;&#1094;&#1100;
            &#1085;&#1077; &#1085;&#1077;&#1089;&#1077;
            &#1074;&#1110;&#1076;&#1087;&#1086;&#1074;&#1110;&#1076;&#1072;&#1083;&#1100;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1079;&#1072; &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1110;
            &#1085;&#1077;&#1097;&#1072;&#1089;&#1085;&#1110;
            &#1074;&#1080;&#1087;&#1072;&#1076;&#1082;&#1080;
            &#1072;&#1073;&#1086;
            &#1087;&#1086;&#1075;&#1110;&#1088;&#1096;&#1077;&#1085;&#1085;&#1103;
            &#1089;&#1090;&#1072;&#1085;&#1091;
            &#1079;&#1076;&#1086;&#1088;&#1086;&#1074;&#39;&#1103;,
            &#1103;&#1082;&#1110;
            &#1074;&#1080;&#1085;&#1080;&#1082;&#1085;&#1091;&#1090;&#1100;
            &#1091;
            &#1088;&#1077;&#1079;&#1091;&#1083;&#1100;&#1090;&#1072;&#1090;&#1110;
            &#1092;&#1110;&#1079;&#1080;&#1095;&#1085;&#1080;&#1093;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100; &#1091;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;, &#1074;
            &#1090;&#1086;&#1084;&#1091; &#1095;&#1080;&#1089;&#1083;&#1110;
            &#1091; &#1088;&#1072;&#1079;&#1110;
            &#1085;&#1072;&#1103;&#1074;&#1085;&#1086;&#1089;&#1090;&#1110;
            &#1087;&#1088;&#1086;&#1090;&#1080;&#1087;&#1086;&#1082;&#1072;&#1079;&#1072;&#1085;&#1100;
            &#1097;&#1086;&#1076;&#1086;
            &#1079;&#1072;&#1085;&#1103;&#1090;&#1100;
            &#1089;&#1087;&#1086;&#1088;&#1090;&#1086;&#1084; &#1090;&#1072;
            &#1092;&#1110;&#1079;&#1080;&#1095;&#1085;&#1080;&#1084;&#1080;
            &#1085;&#1072;&#1074;&#1072;&#1085;&#1090;&#1072;&#1078;&#1077;&#1085;&#1085;&#1103;&#1084;&#1080;;
            &#1076;) &#1050;&#1083;&#1110;&#1108;&#1085;&#1090;
            &#1085;&#1072;&#1076;&#1072;&#1108; &#1089;&#1074;&#1086;&#1102;
            &#1079;&#1075;&#1086;&#1076;&#1091; &#1085;&#1072;
            &#1086;&#1073;&#1088;&#1086;&#1073;&#1082;&#1091;
            &#1081;&#1086;&#1075;&#1086;
            &#1087;&#1077;&#1088;&#1089;&#1086;&#1085;&#1072;&#1083;&#1100;&#1085;&#1080;&#1093;
            &#1076;&#1072;&#1085;&#1080;&#1093;
            &#1042;&#1080;&#1082;&#1086;&#1085;&#1072;&#1074;&#1094;&#1077;&#1084;
            &#1091; &#1073;&#1091;&#1076;&#1100;-&#1103;&#1082;&#1080;&#1093;
            &#1094;&#1110;&#1083;&#1103;&#1093;, &#1103;&#1082;&#1097;&#1086;
            &#1090;&#1072;&#1082;&#1110; &#1094;&#1110;&#1083;&#1110;
            &#1085;&#1077;
            &#1089;&#1091;&#1087;&#1077;&#1088;&#1077;&#1095;&#1072;&#1090;&#1100;
            &#1095;&#1080;&#1085;&#1085;&#1086;&#1084;&#1091;
            &#1079;&#1072;&#1082;&#1086;&#1085;&#1086;&#1076;&#1072;&#1074;&#1089;&#1090;&#1074;&#1091;
            &#1059;&#1082;&#1088;&#1072;&#1111;&#1085;&#1080; &#1090;&#1072;
            &#1085;&#1077;&#1086;&#1073;&#1093;&#1110;&#1076;&#1085;&#1110;
            &#1076;&#1083;&#1103;
            &#1091;&#1082;&#1083;&#1072;&#1076;&#1072;&#1085;&#1085;&#1103;
            &#1076;&#1072;&#1085;&#1086;&#1075;&#1086;
            &#1044;&#1086;&#1075;&#1086;&#1074;&#1086;&#1088;&#1091;
            &#1090;&#1072; &#1081;&#1086;&#1075;&#1086;
            &#1074;&#1080;&#1082;&#1086;&#1085;&#1072;&#1085;&#1085;&#1103;.
          </span>
        </p>
        <p className="c1 c2">
          <span className="c0"></span>
        </p>
        <p className="c1">
          <span className={styles.subheader}>
            10. &#1056;&#1045;&#1050;&#1042;&#1030;&#1047;&#1048;&#1058;&#1048;
            &#1042;&#1048;&#1050;&#1054;&#1053;&#1040;&#1042;&#1062;&#1071;
          </span>
        </p>
        <p className="c1 c2" id="h.gjdgxs">
          <span className="c0"></span>
        </p>
        <p className="c6">
          <span className="c0">
            &#1060;&#1054;&#1055;
            &#1050;&#1086;&#1074;&#1072;&#1083;&#1100;&#1095;&#1091;&#1082;
            &#1054;&#1083;&#1077;&#1082;&#1089;&#1072;&#1085;&#1076;&#1088;&#1072;
            &#1052;&#1080;&#1082;&#1086;&#1083;&#1072;&#1111;&#1074;&#1085;&#1072;{" "}
          </span>
        </p>
        <p className="c6">
          <span className="c9">
            &#1040;&#1076;&#1088;&#1077;&#1089;&#1072;
            &#1057;&#1090;&#1091;&#1076;&#1110;&#1111;: 01001,
            &#1084;.&#1050;&#1080;&#1111;&#1074;, &#1074;&#1091;&#1083;.
            &#1042;&#1086;&#1083;&#1086;&#1076;&#1080;&#1084;&#1080;&#1088;&#1089;&#1100;&#1082;&#1072;,
            10
          </span>
        </p>
        <p className="c6">
          <span className="c0">&#1030;&#1055;&#1053;: 3310017428</span>
        </p>
        <p className="c6">
          <span className="c0">
            &#1055;&#1086;&#1090;&#1086;&#1095;&#1085;&#1080;&#1081;
            &#1088;&#1072;&#1093;&#1091;&#1085;&#1086;&#1082;:
          </span>
        </p>
        <p className="c6">
          <span className="c0">
            UA823052990000026002010122065 &#1091; &#1040;&#1058; &#1050;&#1041;
            &laquo;&#1055;&#1056;&#1048;&#1042;&#1040;&#1058;&#1041;&#1040;&#1053;&#1050;&raquo;
          </span>
        </p>
        <p className="c6">
          <span className="c0">
            &#1057;&#1072;&#1081;&#1090;: https://www.whynotballet.com{" "}
          </span>
        </p>
        <p className="c6">
          <span className="c0">Instagram: WHYNOTBALLET</span>
        </p>
      </div>
    </div>
  );
};

export default PublicOffer;

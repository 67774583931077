import { toast } from "react-toastify";
import { CollectionT } from "./../../models/models";
import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  getFirestore,
  query,
  onSnapshot,
  QuerySnapshot,
  CollectionReference,
  orderBy,
} from "firebase/firestore";

type FirestoreCollectionItem = {
  id?: string;
};

const useFirestore = <T extends FirestoreCollectionItem>(
  collectionName: keyof CollectionT,
  sortFieldName?: string,
  sortDirection: "asc" | "desc" = "desc"
) => {
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const db = getFirestore();

  const handleRefetch = () => {
    getItems();
  };

  useEffect(() => {
    document.addEventListener(`refetch-${collectionName}`, handleRefetch);

    return () =>
      document.removeEventListener(`refetch-${collectionName}`, handleRefetch);
  }, []);

  useEffect(() => {
    setLoading(true);
    getItems();
  }, [db]);

  const loadMore = () => {
    getItems();
  };

  const getItems = () => {
    const q: CollectionReference = collection(db, collectionName);

    let q2;
    if (sortFieldName) {
      q2 = query(q, orderBy(sortFieldName, sortDirection));
    } else {
      q2 = query(q);
    }

    onSnapshot(q2 as any, (querySnapshot: QuerySnapshot<T>) => {
      const fetchedItems = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setItems(fetchedItems);
      setLoading(false);
    });
  };

  const addItem = async (item: Omit<T, "id">) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), item);
      setItems((prevItems) => [...prevItems, { ...item, id: docRef.id } as T]);
    } catch (err) {
      toast.error(JSON.stringify(err));
    }
  };

  const updateItem = async (id: string, updatedItem: Omit<T, "id">) => {
    try {
      const itemRef = doc(db, collectionName, id);
      await setDoc(itemRef, updatedItem);
      setItems((prevItems: any) =>
        prevItems.map((item: T) => {
          if (item.id === id) {
            return { ...updatedItem, id };
          }
          return item;
        })
      );
    } catch (err) {
      toast.error(JSON.stringify(err));
    }
  };

  const deleteItem = async (id: string) => {
    const itemRef = doc(db, collectionName, id);
    await deleteDoc(itemRef);
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return {
    items,
    addItem,
    updateItem,
    deleteItem,
    isLoading: loading,
    loadMore,
  };
};

export default useFirestore;
